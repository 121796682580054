export enum ManagerSupplierAccountStatus {
  Active = 'active',
  Paused = 'paused',
  Blocked = 'blocked',
  Retired = 'retired',
  Banned = 'banned',
  Deleted = 'deleted',
  Deceased = 'deceased',
  Inactive = 'inactive',
}

export enum ManagerSupplierType {
  Both = 'both',
  Translator = 'translator',
  Interpreter = 'interpreter',
  Undefined = 'undefined',
}

export enum ManagerSupplierSex {
  Male = 'male',
  Female = 'female',
  Transgender = 'transgender',
}

export enum ManagerSupplierStandBy {
  Plus = 'plus',
  Yes = 'yes',
  No = 'no',
}

interface ManagerSupplier {
  id: number;
  uid: string;
  key: string;
  label: string;
  gender?: string;
  godmodeSupplierUrl: string;
  type: ManagerSupplierType;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  phoneCode?: string;
  phoneNumber?: string;
  alternativePhone?: string;
  avatarUrl?: string;
  sex?: ManagerSupplierSex;
  accountStatus: {
    name: ManagerSupplierAccountStatus;
    expiresAt?: string;
    comment?: string;
  };
  interpretation: {
    standby: ManagerSupplierStandBy;
    approvedSkills: {
      langFromId?: number;
      langFromCode?: string;
      langToId?: number;
      langToCode?: string;
      dialectFrom?: string;
      dialectTo?: string;
      nativeFrom?: boolean;
      nativeTo?: boolean;
      approvedAt?: string;
      declinedAt?: string;
      createdAt?: string;
      qualification: {
        priority?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        name?: string;
        shortName?: string;
      };
    }[];
    nextJob?: {
      id: number;
      godmodeSupplierUrl: string;
      godmodeDemanderUrl: string;
    };
    statistics: {
      invited: number;
      withdrawn: number;
      showedUp: number;
      late: number;
      noShow: number;
    };
    sessionTypesStatuses?: {
      phone: {
        name?: 'active' | 'paused' | 'blocked' | 'disabled';
        expiresAt?: string;
        comment?: string;
      };
      video: {
        name?: 'active' | 'paused' | 'blocked' | 'disabled';
        expiresAt?: string;
        comment?: string;
      };
      in_person: {
        name?: 'active' | 'paused' | 'blocked' | 'disabled';
        expiresAt?: string;
        comment?: string;
      };
      videoroom: {
        name?: 'active' | 'paused' | 'blocked' | 'disabled';
        expiresAt?: string;
        comment?: string;
      };
    };
  };
  translation: {
    approvedSkills: {
      languageFromId?: number;
      languageFromCode?: string;
      languageToId?: number;
      languageToCode?: string;
      nativeFrom?: boolean;
      nativeTo?: boolean;
      activeFromYear?: number;
      hasContract?: boolean;
      contractMinDeadlineDaysNumber?: number;
      contractMaxAutoAssignedSubtasksNumber?: number;
      approvedAt?: string;
      declinedAt?: string;
      createdAt?: string;
      qualification?: {
        priority?: 1 | 2;
        name?: string;
        shortName?: string;
      };
    }[];
    statistics: {
      draft?: number;
      transferred?: number;
      proactive_access?: number;
      invited?: number;
      applied?: number;
      accepted?: number;
      rejected?: number;
      in_progress?: number;
      review_requested?: number;
      changes_required?: number;
      finished?: number;
      cancelled?: number;
    };
  };
  anniversary: {
    birth?: string;
    birthAnniversary?: boolean;
    accountCreated?: string;
    accountCreatedAnniversary?: boolean;
  };
  address?: {
    city: string;
    country: string;
    county: string;
    latitude: string;
    line1: string;
    line2: string;
    longitude: string;
    postcode: string;
  };
}

export interface SupplierApiResponse {
  supplier: ManagerSupplier;
}

export interface ManagerSuppliersResponse {
  suppliers: ManagerSupplier[];
  statistics: {
    all: number;
    active: number;
    paused: number;
    blocked: number;
    retired: number;
    banned: number;
    deleted: number;
    deceased: number;
    inactive: number;
  };
  count: number;
  page: number;
  pages: number;
}
export default ManagerSupplier;
