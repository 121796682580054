import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Pagination, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetTranslationSkillsQuery } from '../Api/Endpoints/Skills/Skills.hooks';
import useDebounce from '../hooks/useDebounce';
import NewSkillDrawer from '../InterpretationSkills/Drawers/NewSkillDrawer/NewSkillDrawer';
import translationKeys from '../translations/translationKeys';
import { TranslationSkill, TranslationSkillsResponse, TranslationSkillsStatus } from '../types';
import TranslationSkillsTable from './TranslationSkillsTable/TranslationSkillsTable';
import getStatusName from './utils/getStatusName';
import getTranslationSkillsFilters from './utils/getTranslationSkillsFilters';
import TranslationSkillsFilterField from './TranslationSkillsFilterField';
import TranslationSkillsTab from './TranslationSkillsTab';
import styles from './TranslationSkills.module.scss';

export type TranslationSkillsFilters = Values<{
  tab: ParserBuilder<TranslationSkillsTab>;
  id: ParserBuilder<number>;
  name: ParserBuilder<string>;
  languages: ParserBuilder<string[]>;
  qualifications: ParserBuilder<string[]>;
  accountStatuses: ParserBuilder<string[]>;
  createdAt: ParserBuilder<string[]>;
}>;

const TranslationSkills = () => {
  const [skills, setSkills] = useState<TranslationSkill[]>([]);
  const intl = useIntl();
  const [skillsStats, setSkillsStats] = useState<TranslationSkillsResponse['statistics']>();
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    tab: parseAsStringEnum<TranslationSkillsTab>(Object.values(TranslationSkillsTab)),
    id: parseAsInteger,
    name: parseAsString,
    languages: parseAsArrayOf(parseAsString),
    qualifications: parseAsArrayOf(parseAsString),
    accountStatuses: parseAsArrayOf(parseAsString),
    createdAt: parseAsArrayOf(parseAsString),
  });
  const [activeTab, setActiveTab] = useState(filters.tab || TranslationSkillsTab.Approved);
  const [newSkillDrawerOpen, setNewSkillDrawerOpen] = useState(false);
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const tabItems = [
    {
      count: skillsStats?.approved ? skillsStats.approved : undefined,
      id: TranslationSkillsTab.Approved,
      title: getStatusName(TranslationSkillsStatus.Approved, intl),
      filters: {
        's[status_eq]': '30',
      },
    },
    {
      count: skillsStats?.learning ? skillsStats.learning : undefined,
      id: TranslationSkillsTab.Learning,
      title: getStatusName(TranslationSkillsStatus.Learning, intl),
      filters: {
        's[status_eq]': '20',
      },
    },
    {
      count: skillsStats?.pending ? skillsStats.pending : undefined,
      id: TranslationSkillsTab.Pending,
      title: getStatusName(TranslationSkillsStatus.Pending, intl),
      filters: {
        's[status_eq]': '10',
      },
    },
    {
      count: skillsStats?.declined ? skillsStats.declined : undefined,
      id: TranslationSkillsTab.Declined,
      title: getStatusName(TranslationSkillsStatus.Declined, intl),
      filters: {
        's[status_eq]': '40',
      },
    },
    {
      count: skillsStats?.all ? skillsStats.all : undefined,
      id: TranslationSkillsTab.All,
      title: intl.formatMessage({ id: translationKeys.translation_skills_all_tab }),
      filters: { 's[status_eq]': '' },
    },
  ];

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);

    if (activeTabItem) {
      return activeTabItem.filters;
    } else return {};
  };

  const [parsedFilters, setParsedFilters] = useState<Record<string, string | string[]>>({
    ...(tabItems.find((item) => item.id === activeTab)?.filters || {}),
    ...Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getTranslationSkillsFilters(
          acc,
          key as TranslationSkillsFilterField,
          value as string,
        );
      }
      return acc;
    }, {}),
  });

  const [topFilters, setTopFilters] = useState(getTabFilters());

  const {
    data: skillsData,
    isLoading: isSkillsLoading,
    error: skillsError,
    refetch,
  } = useGetTranslationSkillsQuery({
    page,
    's[s]': 'id desc',
    ...stripObject(parsedFilters),
    ...stripObject(topFilters),
  });
  const showLoadMoreButton = !isSkillsLoading && skills && skillsData && page < skillsData.pages;

  const handleFilterChange = (field: TranslationSkillsFilterField, value: string | string[]) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });

    debounceFilterChange(() => {
      setParsedFilters(getTranslationSkillsFilters(parsedFilters, field, value));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  const handleTabSelect = (newActiveTab: string) => {
    setPage(1);
    const newTab = newActiveTab as TranslationSkillsTab;
    const newTopFilters = tabItems.find((item) => item.id === newActiveTab)?.filters || {};
    setFilters({ ...filters, tab: newTab });
    setActiveTab(newTab);
    setTopFilters(newTopFilters);
  };

  useEffect(() => {
    if (!skillsData) return;

    const appendData = skillsData.page > 1;

    setSkills(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...skillsData.skills]
        : skillsData.skills,
    );
    setSkillsStats(skillsData.statistics);
    setIsLoadMoreTriggered(false);
  }, [skillsData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="translation-skills-header">
            <FormattedMessage id={translationKeys.translation_skills_title} />
          </h1>

          <div>
            <Button
              icon={<FontAwesomeIcon icon={faPlus} />}
              data-testid="new-skill-button"
              onClick={() => setNewSkillDrawerOpen(true)}
            >
              <FormattedMessage id={translationKeys.translation_skills_page_new_skill_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} activeTab={activeTab} />
        </div>

        <TranslationSkillsTable
          skills={skills}
          error={skillsError}
          isLoading={isSkillsLoading}
          isLoadMoreTriggered={isLoadMoreTriggered}
          filters={filters}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
          className={styles.tableContainer}
        />

        {showLoadMoreButton && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setIsLoadMoreTriggered(true);
                handleLoadMore();
              }}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.translation_skills_load_more_button} />
            </Button>
          </div>
        )}

        {!isSkillsLoading && !!skills.length && (
          <div>
            <Pagination
              currentPage={page}
              totalPages={skillsData?.pages || 1}
              setPage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>

      <NewSkillDrawer
        show={newSkillDrawerOpen}
        onClose={() => setNewSkillDrawerOpen(false)}
        onComplete={() => handleReloadData()}
      />
    </>
  );
};

export default TranslationSkills;
