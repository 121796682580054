import {
  ManagerOrder,
  ManagerOrderAttachmentResponse,
  ManagerOrderResponse,
  ManagerOrderSubtaskDetail,
  ManagerOrderSubtaskResponse,
  ManagerOrderTask,
  TranslationMethodResponse,
  TranslationSubtasksResponse,
  TranslationSubtaskStats,
} from '../../../types';
import { ManagerRole, ManagersResponse } from '../../../types/Manager';
import { ManagerOrderSubtaskDeadlineRequestStatus } from '../../../types/ManagerOrderSubtaskDetail';
import { ManagerOrderSystemLogResponse } from '../../../types/ManagerOrderSystemLog';
import { ManagerRejectedSellerResponse } from '../../../types/ManagerRejectedSeller';
import { ManagerSuitableSeller } from '../../../types/ManagerSuitableSeller';
import { SavedResponsesResponse } from '../../../types/SavedResponse';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  createTranslationOrder: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerOrder>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders`,
      body,
      loadingCallback,
    });
  },
  getTranslationSubtasks: (
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<TranslationSubtasksResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_subtasks`,
      urlParams,
      loadingCallback,
    });
  },
  getTranslationSubtasksStats: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<TranslationSubtaskStats>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/stats`,
      loadingCallback,
    });
  },
  getOrder: (orderId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerOrderResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}`,
      loadingCallback,
    });
  },
  getOrderAttachments: (
    orderId: string,
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderAttachmentResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/attachments`,
      urlParams,
      loadingCallback,
    });
  },
  uploadOrderAttachments: (
    orderId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderAttachmentResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/attachments`,
      body,
      loadingCallback,
    });
  },
  updateOrderAttachments: (
    orderId: string,
    attachmentId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderAttachmentResponse>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/attachments/${attachmentId}`,
      body,
      loadingCallback,
    });
  },
  downloadOrderAttachments: (
    orderId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/attachment_zip_files`,
      body,
      isBlob: true,
      loadingCallback,
    });
  },
  getOcrAttachments: (orderId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/ocr`,
      loadingCallback,
    });
  },
  updateOrderOwner: (
    orderId: string,
    demanderId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/order_owners/${orderId}`,
      body: { demanderId },
      loadingCallback,
    });
  },
  updateOrder: (
    orderId: string,
    order: Partial<ManagerOrder>,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}`,
      body: { order },
      loadingCallback,
    });
  },
  updateTask: (
    orderId: string,
    taskId: string,
    task: Partial<ManagerOrderTask>,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/tasks/${taskId}`,
      body: { task },
      loadingCallback,
    });
  },
  createTask: (
    orderId: string,
    languageId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/tasks`,
      body: {
        task: {
          status: 'published',
          archived: false,
          targetLanguageId: languageId,
        },
      },
      loadingCallback,
    });
  },
  getOrderSubtask: (subtaskId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerOrderSubtaskResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}`,
      loadingCallback,
    });
  },
  createOrderSubtask: (
    subtask: Partial<ManagerOrderSubtaskDetail>,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderSubtaskResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks`,
      body: { subtask },
      loadingCallback,
    });
  },
  updateOrderSubtask: (
    subtaskId: string,
    subtask: Partial<ManagerOrderSubtaskDetail>,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderSubtaskResponse>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}`,
      body: { subtask },
      loadingCallback,
    });
  },
  getTranslationMethods: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<TranslationMethodResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/sellers/translation_methods`,
      loadingCallback,
    });
  },
  sendOrderEmail: (
    orderId: string,
    emailToSend: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrder>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/emails`,
      body: {
        notificationKey: emailToSend,
      },
      loadingCallback,
    });
  },
  sendSubtaskEmail: (
    subtaskId: string,
    emailToSend: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrder>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}/emails`,
      body: {
        notificationKey: emailToSend,
      },
      loadingCallback,
    });
  },
  getSuitableSellers: (
    subtaskId: string,
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerSuitableSeller[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}/suitable_sellers`,
      urlParams,
      loadingCallback,
    });
  },
  getManagers: (
    name: string,
    role: ManagerRole,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagersResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers`,
      urlParams: {
        's[name_cont]': name,
        's[role_cont]': role,
      },
      loadingCallback,
    });
  },
  deleteRejectedSeller: (
    subtaskId: string,
    sellerId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerSuitableSeller[]>({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}/rejections/${sellerId}`,
      loadingCallback,
    });
  },
  getRejectedSellers: (
    subtaskId: string,
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerRejectedSellerResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}/rejections`,
      urlParams,
      loadingCallback,
    });
  },
  changeDeadlineRequestStatus: (
    subtaskId: string,
    id: string,
    status: ManagerOrderSubtaskDeadlineRequestStatus,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerRejectedSellerResponse>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/subtasks/${subtaskId}/deadline_change_requests/${id}`,
      body: {
        id,
        status,
      },
      loadingCallback,
    });
  },
  getOrderSystemLogs: (
    orderId: string,
    urlParams?: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<ManagerOrderSystemLogResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/system_logs`,
      urlParams: { items: 30, ...urlParams },
      loadingCallback,
    });
  },
  getSavedResponses: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<SavedResponsesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/saved_responses`,
      loadingCallback,
    });
  },
  sendSavedResponse: (
    orderId: string,
    savedResponse: {
      recipientEmail: string;
      savedResponseId: number;
      emailTitle: string;
      emailContent: string;
    },
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/saved_responses`,
      loadingCallback,
      body: { ...savedResponse },
    });
  },
  createSavedResponse: (
    savedResponse: {
      name: string;
      emailTitle: string;
      emailContent: string;
    },
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/saved_responses`,
      loadingCallback,
      body: { ...savedResponse },
    });
  },
};
