import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  faClipboard,
  faDiagramSubtask,
  faHashtag,
  faLanguage,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Tag } from '@skiwo/components';
import classnames from 'classnames';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrder } from '../../types';
import getOrderStatusColour from '../utils/getOrderStatusColour';
import getOrderStatusName from '../utils/getOrderStatusName';
import getSubtaskStatusColour from '../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../utils/getSubtaskStatusName';
import getSubtaskTypeName from '../utils/getSubtaskTypeName';
import CreateSubtaskDrawer from './Drawers/CreateSubtaskDrawer/CreateSubtaskDrawer';
import CreateTaskDrawer from './Drawers/CreateTaskDrawer/CreateTaskDrawer';
import styles from './OrderMenu.module.scss';

enum OrderMenuDrawer {
  CreateTask = 'CREATE_TASK',
  CreateSubtask = 'CREATE_SUBTASK',
}

interface Props {
  order: ManagerOrder;
  onOrderUpdate: () => void;
  hasOwner: boolean;
}

const OrderMenu = ({ order, onOrderUpdate, hasOwner }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeItem = searchParams.get('active') || order.id;
  const [activeDrawer, setActiveDrawer] = useState<OrderMenuDrawer | undefined>(undefined);
  const { getLanguageById } = useLanguages();
  const intl = useIntl();

  const handleItemClick = (id: number | string) => {
    setSearchParams({ active: id.toString() });
  };

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  };

  return (
    <section className={styles.orderMenu}>
      <div>
        <div
          className={classnames(styles.item, { [styles.active]: activeItem == order.id })}
          onClick={() => handleItemClick(order.id)}
        >
          <div data-testid="order-menu-order">
            <FontAwesomeIcon icon={faHashtag} className={styles.icon} />
            <span>
              <FormattedMessage id={translationKeys.translation_order_order_label} /> #{order.id}
            </span>
          </div>
          <span>
            <Tag color={getOrderStatusColour(order.status)}>
              {getOrderStatusName(order.status, intl)}
            </Tag>
          </span>
        </div>
        <hr className={styles.divider} />

        {order.tasks.map((task) => (
          <div key={task.publicId}>
            <div
              className={classnames(styles.item, { [styles.active]: activeItem === task.publicId })}
              onClick={() => handleItemClick(task.publicId || task.id)}
              data-testid="order-menu-task"
            >
              <div data-testid="order-menu-task-title">
                <FontAwesomeIcon icon={faLanguage} className={styles.icon} />
                <span>{getLanguageById(task.targetLanguageId)}</span>
              </div>

              <span className={styles.id} data-testid="order-menu-task-id">
                {task.publicId}
              </span>
            </div>

            <div className={styles.subtasksContainer}>
              {task?.subtasks?.map((subtask) => (
                <div
                  key={subtask.publicId}
                  className={classnames(styles.item, styles.subtask, {
                    [styles.active]: activeItem === subtask.publicId,
                  })}
                  onClick={() => handleItemClick(subtask.publicId)}
                  data-testid="order-menu-subtask"
                >
                  <span data-testid="order-menu-subtask-title">
                    {getSubtaskTypeName(subtask.subtaskType, intl)}
                  </span>

                  <div className={styles.info}>
                    <Tag color={getSubtaskStatusColour(subtask.status)}>
                      <span data-testid="order-menu-subtask-status">
                        {getSubtaskStatusName(subtask.status, intl)}
                      </span>
                    </Tag>
                    {subtask.seller && (
                      <div data-testid="order-menu-subtask-seller">
                        <Avatar url={subtask.seller.avatar} altText={subtask.seller.name}></Avatar>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Button
          size="large"
          variant="gray"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => setActiveDrawer(OrderMenuDrawer.CreateTask)}
          data-testid="oder-menu-new-task-button"
          disabled={!hasOwner}
        >
          <FormattedMessage id={translationKeys.translation_order_menu_new_task} />
        </Button>
        <Button
          size="large"
          variant="gray"
          icon={<FontAwesomeIcon icon={faDiagramSubtask} />}
          onClick={() => setActiveDrawer(OrderMenuDrawer.CreateSubtask)}
          data-testid="oder-menu-new-subtask-button"
          disabled={!hasOwner}
        >
          <FormattedMessage id={translationKeys.translation_order_menu_new_subtask} />
        </Button>
      </div>

      <CreateTaskDrawer
        show={activeDrawer === OrderMenuDrawer.CreateTask}
        order={order}
        onClose={() => {
          onOrderUpdate();
          onDrawerClose();
        }}
      />
      <CreateSubtaskDrawer
        show={activeDrawer === OrderMenuDrawer.CreateSubtask}
        order={order}
        onClose={() => {
          onOrderUpdate();
          onDrawerClose();
        }}
      />
    </section>
  );
};

export default OrderMenu;
