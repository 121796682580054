import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Pagination } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetCustomersQuery } from '../Api/Endpoints/Customers/Customers.hooks';
import useDebounce from '../hooks/useDebounce';
import translationKeys from '../translations/translationKeys';
import { ManagerCustomer, ManagerCustomerType } from '../types';
import CustomersTable from './CustomersTable/CustomersTable';
import getCustomerFilters from './utils/getCustomerFilters';
import CustomersFilterField from './CustomersFilterField';
import styles from './Customers.module.scss';

export type CustomerFilters = Values<{
  id: ParserBuilder<number>;
  type: ParserBuilder<ManagerCustomerType>;
  status: ParserBuilder<string>;
  nameEmailPhone: ParserBuilder<string>;
  orgNumberName: ParserBuilder<string>;
  departments: ParserBuilder<string>;
}>;

const Customers = () => {
  const [customers, setCustomers] = useState<ManagerCustomer[]>([]);
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    id: parseAsInteger,
    type: parseAsStringEnum<ManagerCustomerType>(Object.values(ManagerCustomerType)),
    status: parseAsString,
    nameEmailPhone: parseAsString,
    orgNumberName: parseAsString,
    departments: parseAsString,
  });
  const [parsedFilters, setParsedFilters] = useState<Record<string, string>>(
    Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getCustomerFilters(acc, key as CustomersFilterField, value as string);
      }
      return acc;
    }, {}),
  );

  const {
    data: customersData,
    isLoading: isCustomersLoading,
    error: customersError,
    refetch,
  } = useGetCustomersQuery({
    page,
    's[s]': 'id desc',
    ...stripObject(parsedFilters),
  });
  const showLoadMoreButton =
    !isCustomersLoading && customers && customersData && page < customersData.pages;

  const handleFilterChange = (field: CustomersFilterField, value: string) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });

    debounceFilterChange(() => {
      setParsedFilters(getCustomerFilters(parsedFilters, field, value));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  useEffect(() => {
    if (!customersData) return;

    const appendData = customersData.page > 1;

    setCustomers(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...customersData.customers]
        : customersData.customers,
    );
    setIsLoadMoreTriggered(false);
  }, [customersData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 data-testid="customers-header">
          <FormattedMessage id={translationKeys.customers_page_title} />
        </h1>
      </div>

      <div className={styles.tableContainer}>
        <CustomersTable
          customers={customers}
          error={customersError}
          isLoadMoreTriggered={isLoadMoreTriggered}
          isLoading={isCustomersLoading}
          filters={filters}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
        />
      </div>

      {showLoadMoreButton && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              setIsLoadMoreTriggered(true);
              handleLoadMore();
            }}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
          </Button>
        </div>
      )}
      {!isCustomersLoading && !!customers.length && (
        <div>
          <Pagination
            currentPage={page}
            totalPages={customersData?.pages || 1}
            setPage={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

export default Customers;
