import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Tabs } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskDetail } from '../../../../types';
import { ManagerOrderSubtaskDeadlineRequestStatus } from '../../../../types/ManagerOrderSubtaskDetail';
import { ManagerRejectedSeller } from '../../../../types/ManagerRejectedSeller';
import { ManagerSuitableSeller } from '../../../../types/ManagerSuitableSeller';
import getSubtaskTypeName from '../../../utils/getSubtaskTypeName';
import SellerItem from '../../SellerItem/SellerItem';
import AvailableSellersList from './AvailableSellersList';
import RejectedSellersList from './RejectedSellersList';
import styles from './AssignSellerDrawer.module.scss';

enum AssignSellerTabs {
  Available = 'available',
  Rejected = 'rejected',
}

interface Props {
  show?: boolean;
  onClose: () => void;
  onSubtaskUpdate: () => void;
  subtask: ManagerOrderSubtaskDetail;
}

const AssignSellerDrawer = (props: Props) => {
  const { show, onClose, onSubtaskUpdate, subtask } = props;
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { getLanguageById } = useLanguages();
  const [selectedSellerId, setSelectedSellerId] = useState<string | null>(subtask.sellerId);
  const [assignLoading, setAssignLoading] = useState<boolean>();
  const [availableSellers, setAvailableSellers] = useState<ManagerSuitableSeller[]>([]);
  const [rejectedSellers, setRejectedSellers] = useState<ManagerRejectedSeller[]>([]);
  const [totalAvailableSellers, setTotalAvailableSellers] = useState(0);
  const [totalRejectedSellers, setTotalRejectedSellers] = useState(0);
  const [activeTab, setActiveTab] = useState<AssignSellerTabs>(AssignSellerTabs.Available);
  const [changeDeadlineRequestStatusLoading, setChangeDeadlineRequestStatusLoading] =
    useState<boolean>(false);

  const getAvailableSellers = async (query?: string) => {
    const { data, error } = await api.getSuitableSellers(subtask.id.toString(), {
      name: query,
    });

    if (data) {
      setAvailableSellers(data);

      if (!query) {
        setTotalAvailableSellers(data.length);
      }
    }
    if (error) {
      showErrorToast(error);
    }
  };

  const getRejectedSellers = async (query?: string) => {
    const { data, error } = await api.getRejectedSellers(subtask.id.toString(), {
      name: query || undefined,
    });

    if (data) {
      setRejectedSellers(data.rejections);

      if (!query) {
        setTotalRejectedSellers(data.rejections.length);
        if (!data.rejections.length) setActiveTab(AssignSellerTabs.Available);
      }
    }
    if (error) {
      showErrorToast(error);
    }
  };

  const removeSellerFromRejectedList = async (sellerId: string) => {
    const { error } = await api.deleteRejectedSeller(subtask.id.toString(), sellerId);

    if (!error) {
      getAvailableSellers();
      getRejectedSellers();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  const changeDeadlineRequestStatus = async (status: ManagerOrderSubtaskDeadlineRequestStatus) => {
    if (!(subtask.id && subtask?.deadlineChangeRequest?.id)) return;

    const { error } = await api.changeDeadlineRequestStatus(
      subtask.id.toString(),
      subtask.deadlineChangeRequest.id.toString(),
      status,
      setChangeDeadlineRequestStatusLoading,
    );

    if (!error) {
      onSubtaskUpdate();
      getAvailableSellers();
      getRejectedSellers();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  useEffect(() => {
    getAvailableSellers();
    getRejectedSellers();
  }, []);

  const assignSeller = async () => {
    const { data, error } = await api.updateOrderSubtask(
      subtask.id.toString(),
      {
        sellerId: selectedSellerId,
      },
      setAssignLoading,
    );

    if (data) {
      onClose();
    }

    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: translationKeys.translation_subtask_details_assign_translator_drawer_title,
      })}
      data-testid="edit-subtask-drawer"
    >
      <Form className={styles.editSubtask}>
        <div className={styles.overview}>
          <span className={styles.id}>#{subtask.publicId}</span>
          <div className={styles.title}>
            <span>{getLanguageById(subtask.sourceLanguageId)}</span>
            <FontAwesomeIcon icon={faArrowRight} />
            <span>{getLanguageById(subtask.targetLanguageId)}</span>
          </div>
          <span className={styles.description}>
            {getSubtaskTypeName(subtask.subtaskType, intl)}
          </span>
        </div>

        {subtask.translator && !!Object.keys(subtask.translator).length && (
          <SellerItem
            avatarUrl={subtask.translator?.person?.avatar}
            name={subtask.translator.person.name}
            qualificationName={subtask.translator?.suitableSkill?.qualification.name}
            assignedSubtasksCount={subtask.translator?.assignedSubtasksCount}
            isProactive={subtask.translator?.isProactive}
            hasContract={subtask.translator?.suitableSkill?.hasContract}
            status={subtask.status}
            originalInternalDeadline={subtask.deadlineChangeRequest?.originalInternalDeadline}
            requestedInternalDeadline={subtask.deadlineChangeRequest?.requestedInternalDeadline}
            deadlineChangeRequestedAt={subtask.deadlineChangeRequest?.createdAt}
            deadlineChangeRequestStatus={subtask.deadlineChangeRequest?.status}
            changeDeadlineRequestStatusLoading={changeDeadlineRequestStatusLoading}
            changeDeadlineRequestStatus={changeDeadlineRequestStatus}
          />
        )}

        {!!totalRejectedSellers && (
          <div className={styles.tabsContainer}>
            <Tabs
              items={[
                {
                  id: AssignSellerTabs.Available,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_details_assign_translator_drawer_available_tab,
                  }),
                  count: totalAvailableSellers,
                },
                {
                  id: AssignSellerTabs.Rejected,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_details_assign_translator_drawer_rejected_tab,
                  }),
                  count: totalRejectedSellers,
                },
              ]}
              onSelect={(activeTab) => {
                setActiveTab(activeTab as AssignSellerTabs);
              }}
              activeTab={activeTab}
            />
          </div>
        )}

        {activeTab === AssignSellerTabs.Available && (
          <AvailableSellersList
            availableSellers={availableSellers}
            onSearch={(query) => getAvailableSellers(query)}
            selectedSellerId={selectedSellerId}
            setSelectedSellerId={setSelectedSellerId}
          />
        )}

        {activeTab === AssignSellerTabs.Rejected && rejectedSellers && (
          <RejectedSellersList
            rejectedSellers={rejectedSellers}
            onSearch={(query) => getRejectedSellers(query)}
            onDelete={(id) => removeSellerFromRejectedList(id)}
          />
        )}
      </Form>
      <div className={styles.actions}>
        <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
          <FormattedMessage
            id={translationKeys.translation_subtask_details_assign_translator_drawer_cancel_button}
          />
        </Button>
        <Button
          onClick={() => assignSeller()}
          disabled={!selectedSellerId}
          size="large"
          isLoading={assignLoading}
        >
          <FormattedMessage
            id={translationKeys.translation_subtask_details_assign_translator_drawer_select_button}
          />
        </Button>
      </div>
    </Drawer>
  );
};

export default AssignSellerDrawer;
