import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Table, Tabs, Tag } from '@skiwo/components';
import classNames from 'classnames';
import getFirstLetterCapitalized from '../../../helpers/getFirstLetterCapitalized';
import translationKeys from '../../../translations/translationKeys';
import { JobInvoice } from '../../../types/JobInvoice';
import styles from './FinanceSection.module.scss';

export enum FinanceBreakdownTab {
  Invoice = 'invoice',
  Payout = 'payout',
}

interface InvoiceBreakdownDrawerProps {
  show: boolean;
  onClose: () => void;
  activeTab: FinanceBreakdownTab;
  invoiceData?: JobInvoice;
  payoutData?: JobInvoice;
}

const TabContent = ({ data, activeTab }: { data?: JobInvoice; activeTab: FinanceBreakdownTab }) => {
  if (!data) {
    return null;
  }

  const tagColor = activeTab === FinanceBreakdownTab.Invoice ? 'info' : 'success';
  const filteredLines = data.lines?.filter((line) => Number(line.unitPrice) > 0) || [];

  return (
    <div className={styles.tabContent}>
      <div className={styles.tags}>
        <div className={styles.flexRow}>
          <Tag color={tagColor}>{getFirstLetterCapitalized(data.financeType)}</Tag>
          <Tag color={tagColor}>{data.templateName}</Tag>
        </div>
        {data.status && <Tag color="warning">{data.status}</Tag>}
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>
              <span className={styles.tableHeader}>
                <FormattedMessage id={translationKeys.job_finance_drawer_services_header} />
              </span>
            </th>
            <th>
              <span className={classNames(styles.tableHeader, styles.textRight)}>
                <FormattedMessage id={translationKeys.job_finance_drawer_qty_header} />
              </span>
            </th>
            <th>
              <span className={classNames(styles.tableHeader, styles.textRight)}>
                <FormattedMessage id={translationKeys.job_finance_drawer_rate_header} />
              </span>
            </th>
            <th>
              <span className={classNames(styles.tableHeader, styles.textRight)}>
                <FormattedMessage id={translationKeys.job_finance_drawer_total_header} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredLines.map((line) => (
            <tr key={line.name}>
              <td>{line.description}</td>
              <td>{line.quantity}</td>
              <td>
                <span className={classNames(styles.price, styles.textRight)}>
                  {line.unitPrice && parseFloat(line.unitPrice).toFixed(2)} NOK
                </span>
              </td>
              <td>
                <span className={classNames(styles.price, styles.textRight)}>
                  {line.amount && parseFloat(line.amount).toFixed(2)} NOK
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className={styles.totalPriceRow}>
        <span>
          <FormattedMessage id={translationKeys.job_finance_drawer_total_header} />
        </span>
        <span className={classNames(styles.price, styles.textRight)}>
          {data.totalAmount && data.totalAmount.toFixed(2)} NOK
        </span>
      </div>
    </div>
  );
};

const FinanceDrawer = ({
  show,
  onClose,
  activeTab: preselectedTab,
  invoiceData,
  payoutData,
}: InvoiceBreakdownDrawerProps) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<FinanceBreakdownTab>(preselectedTab);

  return (
    <Drawer
      show={show}
      title={intl.formatMessage({ id: translationKeys.job_finance_drawer_label })}
      onClose={onClose}
    >
      <div className={styles.financeDrawer}>
        <Tabs
          items={[
            {
              id: FinanceBreakdownTab.Invoice,
              title: intl.formatMessage({ id: translationKeys.job_finance_drawer_invoice_tab }),
            },
            {
              id: FinanceBreakdownTab.Payout,
              title: intl.formatMessage({ id: translationKeys.job_finance_drawer_payout_tab }),
            },
          ]}
          onSelect={(activeTab) => {
            setActiveTab(activeTab as FinanceBreakdownTab);
          }}
          activeTab={activeTab}
        />
        <TabContent
          data={activeTab === FinanceBreakdownTab.Invoice ? invoiceData : payoutData}
          activeTab={activeTab}
        />
      </div>
    </Drawer>
  );
};

export default FinanceDrawer;
