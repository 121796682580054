import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Pagination, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetSuppliersQuery } from '../Api/Endpoints/Suppliers/Suppliers.hooks';
import CreateSupplierDrawer from '../Drawers/CreateSupplierDrawer/CreateSupplierDrawer';
import useDebounce from '../hooks/useDebounce';
import translationKeys from '../translations/translationKeys';
import {
  ManagerSupplier,
  ManagerSupplierAccountStatus,
  ManagerSupplierSex,
  ManagerSuppliersResponse,
  ManagerSupplierStandBy,
  ManagerSupplierType,
} from '../types';
import SuppliersTable from './SuppliersTable/SuppliersTable';
import getStatusName from './utils/getStatusName';
import getSuppliersFilters from './utils/getSuppliersFilters';
import SuppliersFilterField from './SuppliersFilterField';
import SupplierTabs from './SupplierTab';
import styles from './Suppliers.module.scss';

export type SuppliersFilters = Values<{
  tab: ParserBuilder<SupplierTabs>;
  id: ParserBuilder<number>;
  type: ParserBuilder<ManagerSupplierType>;
  nameEmailPhone: ParserBuilder<string>;
  gender: ParserBuilder<ManagerSupplierSex>;
  standby: ParserBuilder<ManagerSupplierStandBy>;
}>;

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState<ManagerSupplier[]>([]);
  const intl = useIntl();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [suppliersStats, setSuppliersStats] = useState<ManagerSuppliersResponse['statistics']>();
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    tab: parseAsStringEnum<SupplierTabs>(Object.values(SupplierTabs)),
    id: parseAsInteger,
    type: parseAsStringEnum<ManagerSupplierType>(Object.values(ManagerSupplierType)),
    nameEmailPhone: parseAsString,
    gender: parseAsStringEnum<ManagerSupplierSex>(Object.values(ManagerSupplierSex)),
    standby: parseAsStringEnum<ManagerSupplierStandBy>(Object.values(ManagerSupplierStandBy)),
  });
  const [activeTab, setActiveTab] = useState(filters.tab || SupplierTabs.Active);
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const tabItems = [
    {
      count: suppliersStats?.active,
      id: SupplierTabs.Active,
      title: getStatusName(ManagerSupplierAccountStatus.Active, intl),
      filters: {
        's[current_account_status_name_eq]': '0',
      },
    },
    {
      count: suppliersStats?.paused,
      id: SupplierTabs.Paused,
      title: getStatusName(ManagerSupplierAccountStatus.Paused, intl),
      filters: {
        's[current_account_status_name_eq]': '1',
      },
    },
    {
      count: suppliersStats?.blocked,
      id: SupplierTabs.Blocked,
      title: getStatusName(ManagerSupplierAccountStatus.Blocked, intl),
      filters: {
        's[current_account_status_name_eq]': '2',
      },
    },
    {
      count: suppliersStats?.retired,
      id: SupplierTabs.Retired,
      title: getStatusName(ManagerSupplierAccountStatus.Retired, intl),
      filters: {
        's[current_account_status_name_eq]': '3',
      },
    },
    {
      count: suppliersStats?.banned,
      id: SupplierTabs.Banned,
      title: getStatusName(ManagerSupplierAccountStatus.Banned, intl),
      filters: {
        's[current_account_status_name_eq]': '4',
      },
    },
    {
      count: suppliersStats?.deleted,
      id: SupplierTabs.Deleted,
      title: getStatusName(ManagerSupplierAccountStatus.Deleted, intl),
      filters: {
        's[current_account_status_name_eq]': '5',
      },
    },
    {
      count: suppliersStats?.deceased,
      id: SupplierTabs.Deceased,
      title: getStatusName(ManagerSupplierAccountStatus.Deceased, intl),
      filters: {
        's[current_account_status_name_eq]': '6',
      },
    },
    {
      count: suppliersStats?.all,
      id: SupplierTabs.All,
      title: intl.formatMessage({ id: translationKeys.suppliers_page_all_tab }),
      filters: { 's[current_account_status_name_eq]': '' },
    },
  ];

  const [parsedFilters, setParsedFilters] = useState<Record<string, string>>({
    ...(tabItems.find((item) => item.id === activeTab)?.filters || {}),
    ...Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getSuppliersFilters(acc, key as SuppliersFilterField, value as string);
      }
      return acc;
    }, {}),
  });

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);
    if (activeTabItem) {
      return activeTabItem.filters;
    } else return {};
  };

  const [topFilters, setTopFilters] = useState(getTabFilters());

  const {
    data: suppliersData,
    isLoading: isSuppliersLoading,
    error: suppliersError,
    refetch,
  } = useGetSuppliersQuery({
    page,
    's[s]': 'id desc',
    ...stripObject(parsedFilters),
    ...stripObject(topFilters),
  });
  const showLoadMoreButton =
    !isSuppliersLoading && suppliers && suppliersData && page < suppliersData.pages;

  const handleFilterChange = (field: SuppliersFilterField, value: string) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });

    debounceFilterChange(() => {
      setParsedFilters(getSuppliersFilters(parsedFilters, field, value));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  const handleTabSelect = (newActiveTab: string) => {
    setPage(1);
    const newTab = newActiveTab as SupplierTabs;
    const newTopFilters = tabItems.find((item) => item.id === newActiveTab)?.filters || {};
    setFilters({ ...filters, tab: newTab });
    setActiveTab(newTab);
    setTopFilters(newTopFilters);
  };

  useEffect(() => {
    if (!suppliersData) return;

    const appendData = suppliersData.page > 1;

    setSuppliers(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...suppliersData.suppliers]
        : suppliersData.suppliers,
    );
    setSuppliersStats(suppliersData.statistics);
    setIsLoadMoreTriggered(false);
  }, [suppliersData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="suppliers-title">
            <FormattedMessage id={translationKeys.suppliers_page_title} />
          </h1>
          <div>
            <Button
              data-testid="new-supplier-button"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setCreateModalOpen(true)}
            >
              <FormattedMessage id={translationKeys.suppliers_page_new_supplier_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs
            items={tabItems}
            onSelect={handleTabSelect}
            data-testid="supplier-tabs"
            activeTab={activeTab}
          />
        </div>

        <div className={styles.tableContainer}>
          <SuppliersTable
            suppliers={suppliers}
            error={suppliersError}
            isLoadMoreTriggered={isLoadMoreTriggered}
            isLoading={isSuppliersLoading}
            filters={filters}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
          />
        </div>

        {showLoadMoreButton && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setIsLoadMoreTriggered(true);
                handleLoadMore();
              }}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
            </Button>
          </div>
        )}

        {!isSuppliersLoading && !!suppliers.length && (
          <div>
            <Pagination
              currentPage={page}
              totalPages={suppliersData?.pages || 1}
              setPage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>

      <CreateSupplierDrawer
        show={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onComplete={() => handleReloadData()}
      />
    </>
  );
};

export default Suppliers;
