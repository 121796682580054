import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faMessages, faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, IconButton, Skeleton, TextField } from '@skiwo/components';
import { Tag } from '@skiwo/components';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import translationKeys from '../../translations/translationKeys';
import styles from './ChatSection.module.scss';

interface Message {
  id: number;
  text?: string;
  createdAt: string;
  own: boolean;
  author: {
    uid: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

interface ChatSectionProps {
  messages?: Message[];
  sendMessage: (message: string) => void;
  isSubmitLoading?: boolean;
}

const ChatSection = ({
  messages: messagesData,
  sendMessage,
  isSubmitLoading,
}: ChatSectionProps) => {
  const [messages, setMessages] = useState<Record<string, Message[]> | undefined>();
  const [newMessage, setNewMessage] = useState('');
  const intl = useIntl();

  const getDateLabel = (createdAt: string) => {
    const date = new Date(createdAt);

    if (isToday(date)) return intl.formatMessage({ id: translationKeys.chat_section_today_label });
    if (isYesterday(date))
      return intl.formatMessage({
        id: translationKeys.chat_section_yesterday_label,
      });

    return format(date, 'd MMM');
  };

  const getChatTime = (createdAt: string) => {
    const date = new Date(createdAt);

    return format(date, 'HH:mm');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendMessage(newMessage);
    setNewMessage('');
  };

  useEffect(() => {
    if (messagesData) {
      const groupedChat = messagesData.reduce<Record<string, Message[]>>((acc, message) => {
        const date = new Date(message.createdAt);
        const key = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
        
        if (!acc[key]) {
          acc[key] = [];
        }
      
        acc[key].push(message);
      
        return acc;
      }, {});

      setMessages(groupedChat);
    }
  }, [messagesData]);

  return (
    <section className={styles.chatSection}>
      <div className={styles.header}>
        <FontAwesomeIcon icon={faMessages} />
        <span>
          <FormattedMessage id={translationKeys.chat_section_title} />
        </span>
        {messages && (
          <Tag color="neutral" variant="square" size="small">
            {Object.values(messages).reduce((count, arr) => count + arr.length, 0)}
          </Tag>
        )}
      </div>

      <hr className={styles.divider} />

      <div className={styles.messagesContainer}>
        {!messages ||
          (Object.keys(messages).length === 0 && (
            <div className={styles.noMessages}>
              <FontAwesomeIcon icon={faMessages} className={styles.icon} />
              <FormattedMessage id={translationKeys.chat_section_no_messages} />
            </div>
          ))}

        {!messages && (
          <div>
            <div className={styles.date}>
              <span className={styles.skeleton}>
                <Skeleton />
              </span>
            </div>

            {[...Array(3)].map((_, index) => (
              <div key={index} className={styles.message}>
                <div className={styles.messageHeader}>
                  <Avatar altText="Loading..." />
                  <span className={styles.skeleton}>
                    <Skeleton />
                  </span>
                </div>
                <p className={styles.messageBody}>
                  <span className={styles.skeleton}>
                    <Skeleton />
                  </span>
                </p>
              </div>
            ))}
          </div>
        )}

        {messages &&
          Object.entries(messages).map(([date, value]) => (
            <div key={date}>
              <span className={styles.date}>{getDateLabel(date)}</span>

              {value.map((message) => (
                <div key={message.id} className={styles.message}>
                  <div className={styles.messageHeader}>
                    <Avatar
                      url={message.author.avatar}
                      altText={`${message.author.firstName} ${message.author.lastName}`}
                    />
                    <span>{`${message.author.firstName} ${message.author.lastName}`}</span>
                    <span className={styles.time}>{getChatTime(message.createdAt)}</span>
                  </div>
                  <p className={styles.messageBody}>{message.text}</p>
                </div>
              ))}
            </div>
          ))}
      </div>

      <form onSubmit={handleSubmit} className={styles.footer}>
        <TextField
          placeholder={intl.formatMessage({
            id: translationKeys.chat_section_new_message_placeholder,
          })}
          size="large"
          className={styles.newMessage}
          value={newMessage}
          onChange={(e) => setNewMessage(e.currentTarget.value)}
        />
        <IconButton
          icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
          disabled={!newMessage}
          isLoading={isSubmitLoading}
          type="submit"
        />
      </form>
    </section>
  );
};

export default ChatSection;
