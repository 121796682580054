import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ManagerJobCancellationReason,
  ManagerJobDowngradeRequirement,
} from '../../../types/ManagerJob';
import { ManagerJobUpdateStatement } from '../../../types/ManagerJobStatement';
import { UrlParams } from '../../fetchApi';
import { Jobs } from '..';

export const jobQueryKeys = {
  all: ['jobs'] as const,
  filters: (filters: UrlParams) => [...jobQueryKeys.all, { filters }],
  job: (id?: string) => (id ? ([...jobQueryKeys.all, id] as const) : jobQueryKeys.all),
  jobNotes: (id?: string) =>
    id ? ([...jobQueryKeys.all, 'notes', id] as const) : jobQueryKeys.all,
  jobStatement: (id?: string) =>
    id ? ([...jobQueryKeys.all, id, 'statement'] as const) : jobQueryKeys.all,
  jobSupplierInvoices: (id?: string) =>
    id ? ([...jobQueryKeys.all, id, 'supplierInvoices'] as const) : jobQueryKeys.all,
  jobDemanderInvoices: (id?: string) =>
    id ? ([...jobQueryKeys.all, id, 'demanderInvoices'] as const) : jobQueryKeys.all,
  jobSystemLogs: (id?: string, urlParams?: UrlParams) =>
    id
      ? urlParams
        ? ([...jobQueryKeys.all, id, 'systemLogs', urlParams] as const)
        : ([...jobQueryKeys.all, id, 'systemLogs'] as const)
      : jobQueryKeys.all,
  jobInvitationLogs: (id?: string, urlParams?: UrlParams) =>
    id
      ? urlParams
        ? ([...jobQueryKeys.all, id, 'invitationLogs', urlParams] as const)
        : ([...jobQueryKeys.all, id, 'invitationLogs'] as const)
      : jobQueryKeys.all,
};

export const useCreateJobMutation = () =>
  useMutation({
    mutationFn: (body: FormData) => Jobs.createJob(body),
  });

export const useGetJobQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.job(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJob(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useGetJobsQuery = (urlParams: UrlParams, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: jobQueryKeys.filters(urlParams),
    queryFn: () => Jobs.getJobs(urlParams).then((res) => res.data),
    ...options,
  });

export const useGetJobStatementQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.jobStatement(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJobStatement(id).then((res) => res.data);
    },
  });

export const useUpdateJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, body }: { id: number; body: FormData }) => Jobs.updateJob(id, body),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useLockJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => Jobs.lockJob(id),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useDowngradeJobRequirementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      requirement,
    }: {
      id: string;
      requirement: ManagerJobDowngradeRequirement;
    }) => Jobs.downgradeJobRequirement(id, requirement),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useUpdateJobStatementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      statementId,
      statement,
    }: {
      id: string;
      statementId: string;
      statement: Partial<ManagerJobUpdateStatement>;
    }) => Jobs.updateJobStatement(id, statementId, statement),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.jobStatement(variables.id.toString()),
      });
    },
  });
};

export const useCancelJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      reason,
      other,
      description,
      sendEmail,
      cancellationDate,
      adminOther,
      realPersonName,
    }: {
      id: number;
      reason: ManagerJobCancellationReason;
      other: boolean;
      description?: string;
      adminOther?: string;
      sendEmail: boolean;
      cancellationDate?: Date;
      realPersonName?: string;
    }) =>
      Jobs.cancelJob(id, {
        reason,
        other,
        description,
        send_email: sendEmail,
        cancellation_date: cancellationDate,
        admin_other: adminOther,
        real_person_name: realPersonName,
      }),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id.toString()),
      });
    },
  });
};

export const useCheckForDuplicateJobIdsMutation = () => {
  return useMutation({
    mutationFn: (body: FormData) => Jobs.checkForDuplicateJobIds(body),
  });
};

export const useSetJobReadyForFinanceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, reason }: { id: string; reason?: string }) =>
      Jobs.setReadyForFinance(id, reason),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id.toString()),
      });
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.jobNotes(id.toString()),
      });
    },
  });
};

export const useUnsetJobReadyForFinanceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => Jobs.unsetReadyForFinance(id),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id.toString()),
      });
    },
  });
};

export const usePostJobNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, note }: { id: string; note: string }) => Jobs.postJobNote(id, note),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.jobNotes(id.toString()),
      });
    },
  });
};

export const useGetJobNotesQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.jobNotes(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJobNotes(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useGetSupplierInvoicesQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.jobSupplierInvoices(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJobSupplierInvoice(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useGetDemanderInvoicesQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.jobDemanderInvoices(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJobDemanderInvoice(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useFinishJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => Jobs.finishJob(id),
    onSuccess(_data, id) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id),
      });
    },
  });
};

export const useWithdrawInterpreterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      body,
    }: {
      id: string;
      body: { supplierNote?: string; internalNote?: string };
    }) => Jobs.withdrawInterpreter(id, body),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id),
      });
    },
  });
};

export const useGetJobSystemLogs = ({ id, urlParams }: { id?: string; urlParams: UrlParams }) =>
  useQuery({
    queryKey: jobQueryKeys.jobSystemLogs(id, urlParams),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getSystemLogs(id, urlParams).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useGetJobInvitationLogs = ({ id, urlParams }: { id?: string; urlParams: UrlParams }) =>
  useQuery({
    queryKey: jobQueryKeys.jobInvitationLogs(id, urlParams),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getInvitationLogs(id, urlParams).then((res) => res.data);
    },
    enabled: !!id,
  });
