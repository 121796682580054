import React from 'react';
import { FormattedMessage } from 'react-intl';
import translationKeys from '../translations/translationKeys';
import styles from './PageNotFound.module.scss';

export const PageNotFound = () => {
  return (
    <div className={styles.pageNotFound}>
      <h1>404</h1>
      <h3>
        <FormattedMessage id={translationKeys.global_page_not_found} />
      </h3>
    </div>
  );
};
