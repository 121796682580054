import { CreateSupplierResponse } from '../../../Drawers/CreateSupplierDrawer/CreateSupplierDrawer';
import { ManagerSuppliersResponse } from '../../../types';
import { SupplierApiResponse } from '../../../types/ManagerSupplier';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  getSuppliers: (urlParams?: UrlParams) => {
    return fetchApi<ManagerSuppliersResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/suppliers`,
        urlParams,
      },
      true,
    );
  },
  getSupplierDetails: (supplierId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<SupplierApiResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/suppliers/${supplierId}`,
      loadingCallback,
    });
  },
  createSupplier: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<CreateSupplierResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/suppliers`,
      body,
      loadingCallback,
    });
  },
  updateSupplier: (
    supplierId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<CreateSupplierResponse>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/suppliers/${supplierId}`,
      body,
      loadingCallback,
    });
  },
};
