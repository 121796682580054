enum SupplierTabs {
  Active = 'active',
  Paused = 'paused',
  Blocked = 'blocked',
  Retired = 'retired',
  Banned = 'banned',
  Deleted = 'deleted',
  Deceased = 'deceased',
  All = 'all',
}

export default SupplierTabs;
