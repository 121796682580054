import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, Table, TextField } from '@skiwo/components';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import translationKeys from '../../translations/translationKeys';
import { ManagerEnterprise } from '../../types';
import EnterpriseItem from '../EnterpriseItem/EnterpriseItem';
import EnterpriseItemSkeleton from '../EnterpriseItemSkeleton/EnterpriseItemSkeleton';
import { EnterprisesFilters } from '../Enterprises';
import EnterprisesFilterField from '../EnterprisesFilterField';
import styles from './EnterprisesTable.module.scss';

interface Props {
  enterprises: ManagerEnterprise[];
  isLoading: boolean;
  isLoadMoreTriggered: boolean;
  error: ApiError | null;
  filters: EnterprisesFilters;
  onFilterChange: (field: EnterprisesFilterField, value: string) => void;
  onCompleteAction: (hardReload: boolean) => void;
}

const EnterprisesTable = ({
  enterprises,
  isLoading,
  isLoadMoreTriggered,
  error,
  filters,
  onFilterChange,
  onCompleteAction,
}: Props) => {
  const intl = useIntl();

  const handleFilterChange = (field: EnterprisesFilterField, value: string) => {
    onFilterChange(field, value);
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  if (error) {
    return (
      <div className={styles.emptyStateContainer} data-testid="page-error-empty-state">
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({ id: translationKeys.enterprises_page_error_state_title })}
          description={intl.formatMessage({
            id: translationKeys.enterprises_page_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="enterprises-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.enterprises_page_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) =>
                  handleFilterChange(EnterprisesFilterField.Id, e.currentTarget.value)
                }
                value={filters.id || ''}
              />
            </th>
            <th className={styles.orgNumberFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.enterprises_page_org_number_filter_placeholder,
                })}
                data-testid="org-number-filter"
                onChange={(e) =>
                  handleFilterChange(EnterprisesFilterField.OrgNumber, e.currentTarget.value)
                }
                value={filters.orgNumber || ''}
              />
            </th>
            <th className={styles.orgNameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.enterprises_page_org_name_filter_placeholder,
                })}
                data-testid="org-name-filter"
                onChange={(e) =>
                  handleFilterChange(EnterprisesFilterField.OrgName, e.currentTarget.value)
                }
                value={filters.orgName || ''}
              />
            </th>
            <th className={styles.ownerNameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.enterprises_page_owner_filter_placeholder,
                })}
                data-testid="owner-name-filter"
                onChange={(e) =>
                  handleFilterChange(EnterprisesFilterField.Owner, e.currentTarget.value)
                }
                value={filters.owner || ''}
              />
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_org_number_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_org_name_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_owner_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_departments_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_members_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.enterprises_page_posted_jobs_header} />
            </th>
          </tr>
        </thead>

        <tbody>
          {!(isLoading && !isLoadMoreTriggered) &&
            enterprises.length >= 0 &&
            enterprises.map((enterprise) => (
              <EnterpriseItem
                enterprise={enterprise}
                key={enterprise.id}
                onCompleteAction={handleCompleteAction}
              />
            ))}

          {isLoading && [...Array(3)].map((_, index) => <EnterpriseItemSkeleton key={index} />)}
        </tbody>
      </Table>

      {!isLoading && enterprises.length === 0 && (
        <div className={styles.emptyStateContainer} data-testid="page-empty-state">
          <EmptyState
            image={errorStateIllustration}
            text={intl.formatMessage({ id: translationKeys.enterprises_page_empty_state_title })}
            description={intl.formatMessage({
              id: translationKeys.enterprises_page_empty_state_subtitle,
            })}
          />
        </div>
      )}
    </>
  );
};

export default EnterprisesTable;
