import { useQuery } from '@tanstack/react-query';
import { UrlParams } from '../../fetchApi';
import Skills from './Skills';

export const skillsQueryKeys = {
  translationSkills: ['translationSkills'],
  translationSkillsFilters: (filters: UrlParams) => [
    ...skillsQueryKeys.translationSkills,
    { filters },
  ],
  interpretationSkills: ['interpretationSkills'],
  interpretationSkillsFilters: (filters: UrlParams) => [
    ...skillsQueryKeys.interpretationSkills,
    { filters },
  ],
  qualifications: ['qualifications'],
};

export const useGetTranslationSkillsQuery = (
  urlParams: UrlParams,
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: skillsQueryKeys.translationSkillsFilters(urlParams),
    queryFn: () => Skills.getTranslationSkills(urlParams).then((res) => res.data),
    ...options,
  });

export const useGetInterpretationSkillsQuery = (
  urlParams: UrlParams,
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: skillsQueryKeys.interpretationSkillsFilters(urlParams),
    queryFn: () => Skills.getInterpretationSkills(urlParams).then((res) => res.data),
    ...options,
  });

export const useGetQualifications = (
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: skillsQueryKeys.qualifications,
    queryFn: () => Skills.getQualifications().then((res) => res.data),
    ...options,
  });
