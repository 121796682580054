import { useQuery } from '@tanstack/react-query';
import { UrlParams } from '../../fetchApi';
import Suppliers from './Suppliers';

export const suppliersQueryKeys = {
  all: ['suppliers'],
  filters: (filters: UrlParams) => ['suppliers', { filters }],
};

export const useGetSuppliersQuery = (urlParams: UrlParams, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: suppliersQueryKeys.filters(urlParams),
    queryFn: () => Suppliers.getSuppliers(urlParams).then((res) => res.data),
    ...options,
  });
