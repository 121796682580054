import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Pagination, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetTranslationSubtasksQuery } from '../Api/Endpoints/Translations/Translations.hooks';
import useDebounce from '../hooks/useDebounce';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { TranslationSubtask, TranslationSubtaskStatus } from '../types';
import { TranslationSubtaskCustomerInput } from '../types/TranslationSubtask';
import TranslationSubtasksTable from './TranslationSubtasksTable/TranslationSubtasksTable';
import getStatusName from './utils/getStatusName';
import getTranslationSubtasksFilters, { statusCodes } from './utils/getTranslationSubtasksFilters';
import TranslationSubtasksFilterField from './TranslationSubtasksFilterField';
import TranslationSubtasksTab from './TranslationSubtasksTab';
import styles from './TranslationSubtasks.module.scss';

export type TranslationSubtasksFilters = Values<{
  tab: ParserBuilder<TranslationSubtasksTab>;
  id: ParserBuilder<number>;
  orderId: ParserBuilder<number>;
  document: ParserBuilder<string>;
  status: ParserBuilder<(TranslationSubtaskStatus | TranslationSubtaskCustomerInput)[]>;
  customerInput: ParserBuilder<string>;
  owner: ParserBuilder<string>;
  languages: ParserBuilder<string[]>;
  externalDeadline: ParserBuilder<string[]>;
  internalDeadline: ParserBuilder<string[]>;
  createdAt: ParserBuilder<string[]>;
  translator: ParserBuilder<string>;
  wordCount: ParserBuilder<number>;
}>;

interface TabItem {
  count?: number;
  id: TranslationSubtasksTab;
  title: string;
  filters: Record<string, string>;
}

type Filters = Record<string, string | string[]>;

const TranslationSubtasks = () => {
  const intl = useIntl();
  const api = useApi();
  const [subtasks, setSubtasks] = useState<TranslationSubtask[]>([]);
  const [activeTab, setActiveTab] = useState<TranslationSubtasksTab>(TranslationSubtasksTab.All);
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    tab: parseAsStringEnum<TranslationSubtasksTab>(Object.values(TranslationSubtasksTab)),
    id: parseAsInteger,
    orderId: parseAsInteger,
    document: parseAsString,
    status: parseAsArrayOf(
      parseAsStringEnum<TranslationSubtaskStatus | TranslationSubtaskCustomerInput>([
        ...Object.values(TranslationSubtaskStatus),
        ...Object.values(TranslationSubtaskCustomerInput),
      ]),
    ),
    customerInput: parseAsString,
    owner: parseAsString,
    languages: parseAsArrayOf(parseAsString),
    externalDeadline: parseAsArrayOf(parseAsString),
    internalDeadline: parseAsArrayOf(parseAsString),
    createdAt: parseAsArrayOf(parseAsString),
    translator: parseAsString,
    wordCount: parseAsInteger,
  });
  const [parsedFilters, setParsedFilters] = useState<Filters>({
    ...Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getTranslationSubtasksFilters(
          acc,
          key as TranslationSubtasksFilterField,
          value as string,
        );
      }
      return acc;
    }, {}),
  });
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const [tabItems, setTabItems] = useState<TabItem[]>([
    {
      id: TranslationSubtasksTab.Draft,
      title: getStatusName(TranslationSubtaskStatus.Draft, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Draft].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.Invited,
      title: getStatusName(TranslationSubtaskStatus.Invited, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Invited].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.Rejected,
      title: getStatusName(TranslationSubtaskStatus.Rejected, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Rejected].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.ReviewRequested,
      title: getStatusName(TranslationSubtaskStatus.ReviewRequested, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.ReviewRequested].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.All,
      title: intl.formatMessage({ id: translationKeys.translation_subtasks_all_tab }),
      filters: {},
    },
  ]);

  const {
    data: subtasksData,
    isLoading: isSubtasksLoading,
    error: subtasksError,
    refetch,
  } = useGetTranslationSubtasksQuery({
    page,
    items: 30,
    ...stripObject(parsedFilters),
  });
  const showLoadMoreButton =
    !isSubtasksLoading && subtasks && subtasksData && page < subtasksData.pages;

  const fetchTranslationsWithNewFilters = (newFilters: Filters) => {
    setSubtasks([]);
    setParsedFilters(newFilters);
  };

  const handleTabSelect = (tab: string) => {
    setActiveTab(tab as TranslationSubtasksTab);
    handleFilterChange(TranslationSubtasksFilterField.Status, [tab]);
  };

  const syncTabsWithFilters = (field: TranslationSubtasksFilterField, value: string[]) => {
    if (field !== TranslationSubtasksFilterField.Status) return;

    const hasMultipleSelected = value.length > 1;
    const isAllActive = hasMultipleSelected || !tabItems.find((item) => item.id === value[0]);

    setActiveTab(isAllActive ? TranslationSubtasksTab.All : (value[0] as TranslationSubtasksTab));
  };

  const handleFilterChange = (field: TranslationSubtasksFilterField, value: string | string[]) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });

    const newFilters = getTranslationSubtasksFilters(parsedFilters, field, value);

    if (Array.isArray(value)) syncTabsWithFilters(field, value);

    debounceFilterChange(() => {
      fetchTranslationsWithNewFilters(newFilters);
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  const getSubtaskStats = async () => {
    const { data } = await api.getTranslationSubtasksStats();

    if (data) {
      setTabItems((prev) =>
        prev.map((item) => ({
          ...item,
          count:
            item.id === TranslationSubtasksTab.All ? data.totalCount : data.statusCounts[item.id],
        })),
      );
    }
  };

  useEffect(() => {
    getSubtaskStats();
  }, []);

  useEffect(() => {
    if (!subtasksData) return;

    const appendData = subtasksData.page > 1;

    setSubtasks(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...subtasksData.subtasks]
        : subtasksData.subtasks,
    );
    setIsLoadMoreTriggered(false);
  }, [subtasksData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="translation-subtasks-header">
            <FormattedMessage id={translationKeys.translation_subtasks_title} />
          </h1>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} activeTab={activeTab} />
        </div>

        <div className={styles.tableContainer}>
          <TranslationSubtasksTable
            subtasks={subtasks}
            error={subtasksError}
            isLoading={isSubtasksLoading}
            isLoadMoreTriggered={isLoadMoreTriggered}
            filters={filters}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
          />
        </div>

        {showLoadMoreButton && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setIsLoadMoreTriggered(true);
                handleLoadMore();
              }}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.translation_subtasks_load_more_button} />
            </Button>
          </div>
        )}

        {!isSubtasksLoading && !!subtasks.length && (
          <div>
            <Pagination
              currentPage={page}
              totalPages={subtasksData?.pages || 1}
              setPage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TranslationSubtasks;
