import { useQuery } from '@tanstack/react-query';
import { UrlParams } from '../../fetchApi';
import Enterprises from './Enterprises';

export const enterprisesQueryKeys = {
  all: ['enterprises'],
  filters: (filters: UrlParams) => ['enterprises', { filters }],
};

export const useGetEnterprisesQuery = (urlParams: UrlParams, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: enterprisesQueryKeys.filters(urlParams),
    queryFn: () => Enterprises.getManagerEnterprises(urlParams).then((res) => res.data),
    ...options,
  });
