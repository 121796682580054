import React from 'react';
import { useIntl } from 'react-intl';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Tag } from '@skiwo/components';
import classNames from 'classnames';
import { ManagerOrderSubtaskDetail, ManagerOrderSubtaskStatus } from '../../../types';
import getSubtaskStatusColour from '../../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../../utils/getSubtaskStatusName';
import styles from './StatusDropdown.module.scss';

interface StatusDropdownProps {
  subtask: ManagerOrderSubtaskDetail;
  onSelect: (status: ManagerOrderSubtaskStatus) => void;
  isLocked?: boolean;
}

export const StatusDropdown = ({ subtask, onSelect, isLocked }: StatusDropdownProps) => {
  const intl = useIntl();
  return (
    <Dropdown
      toggle={
        <Tag
          color={getSubtaskStatusColour(subtask.status)}
          className={classNames(isLocked && styles.isLocked)}
        >
          <div className={classNames(styles.toggle, isLocked && styles.lockedToggle)}>
            {getSubtaskStatusName(subtask.status, intl)}
            <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
          </div>
        </Tag>
      }
      items={Object.values(ManagerOrderSubtaskStatus).map((status) => {
        return {
          id: status,
          text: getSubtaskStatusName(status, intl),
          icon: status === ManagerOrderSubtaskStatus.Draft ? faCircle : faCircleSolid,
          iconClassName: styles[getSubtaskStatusColour(status)],
          'data-testId': status,
          disabled: !subtask.owner?.uid,
        };
      })}
      onSelect={(status) => onSelect(status as ManagerOrderSubtaskStatus)}
      data-testid="status-dropdown"
      disabled={isLocked}
    />
  );
};
