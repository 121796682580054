import { ManagerJobInviteStatus } from '../../types';
import JobsFilterField from '../JobsFilterField';

const statusValues: Record<string, string> = {
  [ManagerJobInviteStatus.Ongoing]: '10',
  [ManagerJobInviteStatus.Paused]: '20',
  [ManagerJobInviteStatus.Finished]: '30',
  [ManagerJobInviteStatus.Disabled]: '40',
  [ManagerJobInviteStatus.NoSuitableInterpreters]: '40',
};

const getJobFilters = (
  currentFilters: Record<string, string | string[]>,
  field: JobsFilterField,
  values: string | string[],
): Record<string, string | string[]> => {
  if (field === JobsFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': values } };
  }

  if (field === JobsFilterField.FinancialStatus) {
    return { ...currentFilters, ...{ 's[finance_status_eq]': values } };
  }

  if (field === JobsFilterField.OwnerAndParticipants) {
    if (Array.isArray(values)) return currentFilters;
    let filterValue = values;
    const isPhone = values.startsWith('+47');

    if (isPhone) {
      filterValue = values.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[owner_cont]': filterValue } };
  }

  if (field === JobsFilterField.StartDate) {
    return {
      ...currentFilters,
      ...{ 's[start_time_gteq]': values[0], 's[start_time_lteq]': values[1] },
    };
  }

  if (field === JobsFilterField.CreatedDate) {
    return {
      ...currentFilters,
      ...{ 's[created_at_gteq]': values[0], 's[created_at_lteq]': values[1] },
    };
  }

  if (field === JobsFilterField.BuyerAndOrderRef) {
    return {
      ...currentFilters,
      ...{ 's[references_cont]': values },
    };
  }

  if (field === JobsFilterField.Language) {
    if (Array.isArray(values) && values.length >= 0) {
      return {
        ...currentFilters,
        ...{ 's[lang_in]': values },
      };
    }

    return {
      ...currentFilters,
    };
  }

  if (field === JobsFilterField.CustomerTemplate) {
    if (Array.isArray(values) && values.length >= 0) {
      return {
        ...currentFilters,
        ...{ 's[demander_pricing_template_id_in]': values },
      };
    }

    return {
      ...currentFilters,
    };
  }

  if (field === JobsFilterField.InterpreterTemplate) {
    if (Array.isArray(values) && values.length >= 0) {
      return {
        ...currentFilters,
        ...{ 's[supplier_pricing_template_id_in]': values },
      };
    }

    return {
      ...currentFilters,
    };
  }

  if (field === JobsFilterField.CategoryAndSubject) {
    return {
      ...currentFilters,
      ...{ 's[category_item_id_eq]': values },
    };
  }

  if (field === JobsFilterField.SessionType) {
    return {
      ...currentFilters,
      ...{ 's[session_type_eq]': values },
    };
  }

  if (field === JobsFilterField.Withdrawal) {
    return {
      ...currentFilters,
      ...{ 's[had_withdrawal_eq]': values },
    };
  }

  if (field === JobsFilterField.QualificationRequested) {
    return {
      ...currentFilters,
      ...{ 's[specific_qualification_requested_eq]': values },
    };
  }

  if (field === JobsFilterField.NeedsManualApproval) {
    if (values === 'true') {
      return {
        ...currentFilters,
        ...{ 's[manual_approval_needed]': 'true' },
        ...{ 's[auto_award_without_applications]': '' },
      };
    }

    if (values === 'false') {
      return {
        ...currentFilters,
        ...{ 's[auto_award_without_applications]': 'true' },
        ...{ 's[manual_approval_needed]': '' },
      };
    }

    if (!values) {
      return {
        ...currentFilters,
        ...{ 's[auto_award_without_applications]': '' },
        ...{ 's[manual_approval_needed]': '' },
      };
    }
  }

  if (field === JobsFilterField.InviteStatus) {
    if (!Array.isArray(values))
      return {
        ...currentFilters,
        ...{ 's[check_invite_status_eq]': statusValues[values] },
      };
  }

  if (field === JobsFilterField.Status) {
    return {
      ...currentFilters,
      ...{ 's[status_in][]': values },
    };
  }

  if (field === JobsFilterField.PaymentMethod) {
    return {
      ...currentFilters,
      ...{ 's[payment_method_cont]': values },
    };
  }

  if (field === JobsFilterField.Interpreter) {
    if (Array.isArray(values)) return currentFilters;
    let filterValue = values;
    const isPhone = values.startsWith('+47');

    if (isPhone) {
      filterValue = values.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[interpreter_cont]': filterValue } };
  }

  return currentFilters;
};

export default getJobFilters;
