import React, { createContext, useContext, useMemo } from 'react';
import { ApiError } from '../Api';
import { useGetQualifications } from '../Api/Endpoints/Skills/Skills.hooks';
import { Qualification } from '../types';
import { useAuth } from './AuthProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface QualificationsContext {
  qualifications: Qualification[];
  isLoading: boolean;
  error: ApiError | null;
  getQualificationById: (qualificationId: number) => Qualification | undefined;
}

export const initialState: QualificationsContext = {
  qualifications: [],
  isLoading: false,
  error: null,
  getQualificationById: () => undefined,
};

export const QualificationsContext = createContext(initialState);

const QualificationsProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const {
    data: qualifications,
    isLoading,
    error,
  } = useGetQualifications({ enabled: isAuthenticated() });

  const getQualificationById = (qualificationId: number) => {
    if (qualifications) {
      const qualificationObject = qualifications.find(
        (qualification: any) => qualification.id === qualificationId,
      );

      if (qualificationObject) {
        return qualificationObject;
      }
    }
  };

  const QualificationsContextValue = useMemo(
    () => ({
      qualifications: qualifications || [],
      isLoading: isLoading,
      error,
      getQualificationById,
    }),
    [qualifications, isLoading],
  );

  return (
    <QualificationsContext.Provider value={QualificationsContextValue}>
      {children}
    </QualificationsContext.Provider>
  );
};

export const useQualifications = () => useContext(QualificationsContext);

export default QualificationsProvider;
