import { ManagerCustomerType } from '../../types';
import CustomersFilterField from '../CustomersFilterField';

const getCustomerFilters = (
  currentFilters: Record<string, string>,
  field: CustomersFilterField,
  value: string,
): Record<string, string> => {
  switch (field) {
    case CustomersFilterField.Id:
      return { ...currentFilters, 's[id_eq]': value };

    case CustomersFilterField.Type:
      switch (value) {
        case ManagerCustomerType.Private:
          return {
            ...currentFilters,
            's[buyer_id_not_null]': '1',
            's[enterprise_membership_id_null]': '1',
            's[enterprise_membership_id_not_null]': '',
            's[buyer_id_null]': '',
          };

        case ManagerCustomerType.Enterprise:
          return {
            ...currentFilters,
            's[enterprise_membership_id_not_null]': '1',
            's[buyer_id_not_null]': '',
            's[enterprise_membership_id_null]': '',
            's[buyer_id_null]': '',
          };

        case ManagerCustomerType.Undefined:
          return {
            ...currentFilters,
            's[enterprise_membership_id_not_null]': '',
            's[buyer_id_not_null]': '',
            's[enterprise_membership_id_null]': '1',
            's[buyer_id_null]': '1',
          };

        default:
          return {
            ...currentFilters,
            's[enterprise_membership_id_not_null]': '',
            's[buyer_id_not_null]': '',
            's[enterprise_membership_id_null]': '',
            's[buyer_id_null]': '',
          };
      }

    case CustomersFilterField.NameEmailPhone: {
      let filterValue = value;
      if (value.startsWith('+47')) {
        filterValue = value.replaceAll(' ', '');
      }
      return { ...currentFilters, 's[lookup_fields_cont]': filterValue };
    }

    case CustomersFilterField.OrgNumberName:
      return { ...currentFilters, 's[enterprise_cont]': value };

    case CustomersFilterField.Departments:
      return { ...currentFilters, 's[departments_cont]': value };

    case CustomersFilterField.Status:
      return { ...currentFilters, 's[account_status_name_eq]': value };

    default:
      return currentFilters;
  }
};

export default getCustomerFilters;
