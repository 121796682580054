enum TranslationSkillsFilterField {
  Id = 'id',
  Name = 'name',
  Languages = 'languages',
  Qualifications = 'qualifications',
  CreatedAt = 'createdAt',
  AccountStatuses = 'accountStatuses',
}

export default TranslationSkillsFilterField;
