import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faList,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DetailItem,
  FileSelector,
  FileState,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { FormikProps } from 'formik';
import defaultFilePreview from '../../assets/default-file-preview.svg';
import CcExpandableToggle from '../../components/CcExpandableToggle/CcExpandableToggle';
import CreateOrderSectionContainer from '../../components/CreateOrderSectionContainer/CreateOrderSectionContainer';
import ExpandableToggle from '../../components/ExpandableToggle/ExpandableToggle';
import generateUniqueIdFromString from '../../helpers/generateUniqueIdFromString';
import { getTypesafeSetFieldValue } from '../../helpers/getTypesafeSetFieldValue';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerCustomer,
  ManagerJobGender,
  ManagerJobGenderProcessing,
  ManagerJobQualificationProcessing,
} from '../../types';
import { Interpreter } from '../../types/Interpreter';
import { ManagerJobBlockedCity } from '../../types/ManagerJob';
import useQueryInterpreterQualificationsPresence from '../hooks/useQueryInterpreterQualificationsPresence';
import { CreateInterpretationOrderFormValues } from '../schema';
import SpecificInterpreterSettingsItem from './SpecificIntepreterSettingsItem';
import styles from './SettingsSection.module.scss';

interface SettingsSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
  customer: Pick<ManagerCustomer, 'emailVerified'>;
  isEditForm?: boolean;
}

export enum InterpreterMatchOptions {
  Qualification = 'Qualification',
  Language = 'Language',
  Gender = 'Gender',
  SessionType = 'SessionType',
}

const NORWEGIAN_BOKMAL_ID = 3

const SettingsSection = ({ formikProps, customer, isEditForm = false }: SettingsSectionProps) => {
  const intl = useIntl();
  const api = useApi();
  const debounceSearch = useDebounce(300);
  const { languages } = useLanguages();

  const languageOptions =
    languages
      .filter((language) => !(language.id === NORWEGIAN_BOKMAL_ID || language.id === parseInt(formikProps.values.languageId)))
      .map((language) => {
        return { id: language.id, label: language.name || '', key: language.id.toString() };
      }) || [];

  const [interpretersQuery, setInterpretersQuery] = useState('');
  const [isIntepretersLoading, setIsInterpretersLoading] = useState(false);
  const [interpretersPagination, setInterpretersPagination] = useState({ page: 1, totalPages: 1 });
  const [interpretersOptions, setInterpretersOptions] = useState<
    SearchDropdownMenuOption<Interpreter>[]
  >([]);

  const { interpreterQualificationOptions } = useQueryInterpreterQualificationsPresence();

  const getInterpreters = async (query: string, page = 1) => {
    const { data } = await api.searchInterpreters(
      {
        page: page > 0 ? page : 1,
        name: query,
        qualificationIds: formikProps.values.qualificationLevelActive
          ? [formikProps.values.specificQualification?.id]
          : undefined,
        languageIds: formikProps.values.languageId ? [formikProps.values.languageId] : undefined,
        gender: formikProps.values.specificGenderActive
          ? formikProps.values.specificGender
          : undefined,
        sessionTypes: [formikProps.values.sessionType],
      },
      setIsInterpretersLoading,
    );

    if (data) {
      const interpreters =
        data.collection.map((interpreter) => {
          return {
            id: generateUniqueIdFromString(interpreter.person.uid),
            label: interpreter.person.name,
            key: interpreter.person.uid,
            customData: interpreter,
          };
        }) || [];

      const appendData = page > 1;
      setInterpretersOptions(appendData ? (prev) => [...prev, ...interpreters] : interpreters);
      setInterpretersPagination({ page: data.pagination.page, totalPages: data.pagination.pages });
    }
  };

  const [addressesOptions, setAddressesOptions] = useState<
    SearchDropdownMenuOption<ManagerJobBlockedCity>[]
  >([]);

  // TODO: This is temporary solutuion. We should create a custom SearchDropdown component for address search

  const getGoogleAddressList = async (query: string) => {
    const { data } = await api.getAddressList({ query, region: 'NO' });

    if (data) {
      const addressOptions: SearchDropdownMenuOption<ManagerJobBlockedCity>[] =
        data.map((address) => {
          const id = generateUniqueIdFromString(address.data.formatted_address);
          return {
            id,
            label: address.data.formatted_address,
            key: id.toString(),
            customData: {
              country:
                address.data.address_components.find((component) =>
                  component.types.includes('country'),
                )?.long_name || '',
              county:
                address.data.address_components.find((component) =>
                  component.types.includes('administrative_area_level_1'),
                )?.long_name || '',
              name: address.data.formatted_address,
              latitude: address.data.geometry.location.lat,
              longitude: address.data.geometry.location.lng,
            },
          };
        }) || [];
      setAddressesOptions(addressOptions);
    }
  };

  const handleFileDownload = (file: FileState) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  const sectionProps = isEditForm
    ? {}
    : {
        icon: <FontAwesomeIcon icon={faList} />,
        title: intl.formatMessage({
          id: translationKeys.create_translation_order_settings_label,
        }),
        marginSize: 'sm' as const,
      };

  return (
    <CreateOrderSectionContainer {...sectionProps} data-testid="settings-section">
      <div className={styles.settingsSection}>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_alternative_language_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_alternative_language_description,
          })}
          active={formikProps.values.alternativeLanguageActive}
          action={
            <B_Form.Check
              data-testid="alternative-language-switch"
              type="switch"
              checked={formikProps.values.alternativeLanguageActive}
              onChange={(e) => {
                setFieldValue('alternativeLanguageActive', e.target.checked);
                setFieldValue('alternativeLanguageTo', undefined);
              }}
            />
          }
        >
          <SearchDropdown
            data-testid="alternative-language-dropdown"
            options={languageOptions}
            selected={
              formikProps.values.alternativeLanguageTo
                ? [
                    languageOptions.find((item) => {
                      return item.id === formikProps.values.alternativeLanguageTo?.id;
                    }) as SearchDropdownMenuOption,
                  ]
                : []
            }
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_alternative_language_label,
            })}
            maxResults={200}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_alternative_language_placeholder,
            })}
            errorText={
              formikProps.touched.alternativeLanguageTo ||
              formikProps.touched.alternativeLanguageActive
                ? formikProps.errors.alternativeLanguageTo
                : undefined
            }
            size="large"
            onChange={(language) => {
              if (language && language.length > 0 && language[0].key) {
                setFieldValue('alternativeLanguageTo', language[0]);
              } else {
                setFieldValue('alternativeLanguageTo', undefined);
              }
            }}
          />
        </ExpandableToggle>
        {!isEditForm && (
          <SpecificInterpreterSettingsItem formikProps={formikProps} customer={customer} />
        )}
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_specific_gender_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_specific_gender_description,
          })}
          active={formikProps.values.specificGenderActive}
          action={
            <B_Form.Check
              data-testid="gender-switch"
              type="switch"
              checked={formikProps.values.specificGenderActive}
              onChange={(e) => {
                setFieldValue('specificGenderActive', e.target.checked);
                setFieldValue('specificGender', ManagerJobGender.Both);
                setFieldValue('genderProcessing', undefined);
              }}
            />
          }
        >
          <div className={styles.gender}>
            <div className={styles.radioItem}>
              <B_Form.Check
                type="radio"
                id="male"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_gender_male,
                })}
                name="specificGender"
                onChange={() => {
                  setFieldValue('specificGender', ManagerJobGender.Male);
                }}
                checked={formikProps.values.specificGender === ManagerJobGender.Male}
              />
            </div>
            <div className={styles.radioItem}>
              <B_Form.Check
                type="radio"
                id="female"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_gender_female,
                })}
                name="specificGender"
                onChange={() => {
                  setFieldValue('specificGender', ManagerJobGender.Female);
                }}
                checked={formikProps.values.specificGender === ManagerJobGender.Female}
              />
            </div>
          </div>

          {(formikProps.touched.specificGender || formikProps.touched.specificGenderActive) &&
            formikProps.errors.specificGender && (
              <span className={styles.errorMessage}>
                <span>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </span>
                {formikProps.errors.specificGender as string}
              </span>
            )}

          {formikProps.values.specificGender && (
            <>
              <span className={styles.radioLabel}>
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_settings_specific_gender_alternative_question
                  }
                />
              </span>
              <div className={styles.radioItem}>
                <B_Form.Check
                  type="radio"
                  id="anyGender"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_specific_gender_alternative_find_any_gender,
                  })}
                  name="genderProcessing"
                  checked={
                    formikProps.values.genderProcessing ===
                    ManagerJobGenderProcessing.GenderAutoUpdate
                  }
                  onChange={() =>
                    setFieldValue('genderProcessing', ManagerJobGenderProcessing.GenderAutoUpdate)
                  }
                />
              </div>

              <div className={styles.radioItem}>
                <B_Form.Check
                  type="radio"
                  id="cancelGender"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_specific_gender_alternative_cancel,
                  })}
                  name="genderProcessing"
                  checked={
                    formikProps.values.genderProcessing ===
                    ManagerJobGenderProcessing.GenderCancelNotFound
                  }
                  onChange={() =>
                    setFieldValue(
                      'genderProcessing',
                      ManagerJobGenderProcessing.GenderCancelNotFound,
                    )
                  }
                />
              </div>
              {(formikProps.touched.genderProcessing || formikProps.touched.specificGenderActive) &&
                formikProps.errors.genderProcessing && (
                  <span className={styles.errorMessage}>
                    <span>
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                    {formikProps.errors.genderProcessing as string}
                  </span>
                )}
            </>
          )}
        </ExpandableToggle>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_qualification_level_label,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_qualification_level_description,
          })}
          active={formikProps.values.qualificationLevelActive}
          action={
            <B_Form.Check
              data-testid="qualification-level-switch"
              type="switch"
              checked={formikProps.values.qualificationLevelActive}
              onChange={(e) => {
                setFieldValue('qualificationLevelActive', e.target.checked);
                setFieldValue('specificQualification', undefined);
                setFieldValue('qualificationProcessing', undefined);
              }}
            />
          }
        >
          <SearchDropdown
            options={interpreterQualificationOptions}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_qualification_level_placeholder,
            })}
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_qualification_level_label,
            })}
            size="large"
            selectedKeys={
              formikProps.values.specificQualification?.key
                ? [formikProps.values.specificQualification.key]
                : []
            }
            onChange={(qualification) => {
              if (qualification && qualification.length > 0 && qualification[0].key) {
                setFieldValue('specificQualification', qualification[0]);
              } else {
                setFieldValue('specificQualification', undefined);
              }
            }}
            errorText={
              formikProps.touched.specificQualification ||
              formikProps.touched.qualificationLevelActive
                ? formikProps.errors.specificQualification
                : undefined
            }
          />
          {formikProps.values.specificQualification && (
            <>
              <span className={styles.radioLabel}>
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_settings_qualification_level_alternative_question
                  }
                />
              </span>
              <div className={styles.radioItem}>
                <B_Form.Check
                  type="radio"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_qualification_level_alternative_find_another_qualification,
                  })}
                  name="qualificationProcessing"
                  onChange={() =>
                    setFieldValue(
                      'qualificationProcessing',
                      ManagerJobQualificationProcessing.qualification_auto_update,
                    )
                  }
                  checked={
                    formikProps.values.qualificationProcessing ===
                    ManagerJobQualificationProcessing.qualification_auto_update
                  }
                />
              </div>

              <div className={styles.radioItem}>
                <B_Form.Check
                  type="radio"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_qualification_level_alternative_cancel,
                  })}
                  name="qualificationProcessing"
                  onChange={() =>
                    setFieldValue(
                      'qualificationProcessing',
                      ManagerJobQualificationProcessing.qualification_cancel_not_found,
                    )
                  }
                  checked={
                    formikProps.values.qualificationProcessing ===
                    ManagerJobQualificationProcessing.qualification_cancel_not_found
                  }
                />
              </div>
              {(formikProps.touched.qualificationProcessing ||
                formikProps.touched.qualificationLevelActive) &&
                formikProps.errors.qualificationProcessing && (
                  <span className={styles.errorMessage}>
                    <span>
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                    {formikProps.errors.qualificationProcessing as string}
                  </span>
                )}
            </>
          )}
        </ExpandableToggle>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_block_interpreters_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_block_interpreters_description,
          })}
          active={formikProps.values.blockInterpretersActive}
          action={
            <B_Form.Check
              data-testid="block-interpreters-switch"
              type="switch"
              checked={formikProps.values.blockInterpretersActive}
              onChange={(e) => {
                setFieldValue('blockInterpretersActive', e.target.checked);
                setFieldValue('blockedInterpreters', undefined);
              }}
            />
          }
        >
          <span className={styles.additionalInfo}>
            <FormattedMessage
              id={translationKeys.create_interpretation_order_settings_block_interpreters_text}
            />
          </span>
          <SearchDropdown
            options={interpretersOptions}
            search
            multiple
            size="large"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_block_interpreters_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_block_interpreters_placeholder,
            })}
            selected={formikProps.values.blockedInterpreters}
            onSearch={(query: string) => {
              setInterpretersQuery(query);
              debounceSearch(() => {
                getInterpreters(query);
              });
            }}
            onChange={(interpreters) => {
              if (interpreters && interpreters.length > 0 && interpreters[0].key) {
                setFieldValue('blockedInterpreters', interpreters);
              } else {
                setInterpretersOptions([]);
                setFieldValue('blockedInterpreters', undefined);
              }
            }}
            pagination={interpretersPagination}
            isLoadingMore={isIntepretersLoading}
            onLoadMore={() => getInterpreters(interpretersQuery, interpretersPagination.page + 1)}
            errorText={
              formikProps.touched.blockedInterpreters || formikProps.touched.blockInterpretersActive
                ? formikProps.errors.blockedInterpreters
                : undefined
            }
          />
        </ExpandableToggle>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_block_cities_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_block_cities_description,
          })}
          active={formikProps.values.blockCitiesActive}
          action={
            <B_Form.Check
              data-testid="block-cities-switch"
              type="switch"
              checked={formikProps.values.blockCitiesActive}
              onChange={(e) => {
                setFieldValue('blockCitiesActive', e.target.checked);
                setFieldValue('blockedCities', undefined);
              }}
            />
          }
        >
          <span className={styles.additionalInfo}>
            <FormattedMessage
              id={translationKeys.create_interpretation_order_settings_block_cities_text}
            />
          </span>
          <SearchDropdown
            options={addressesOptions}
            search
            searchAddress
            multiple
            size="large"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_block_cities_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_block_cities_placeholder,
            })}
            selected={formikProps.values.blockedCities}
            onSearch={(query: string) => {
              debounceSearch(() => {
                getGoogleAddressList(query);
              });
            }}
            onChange={(addresses) => {
              if (addresses && addresses.length > 0 && addresses[0].key) {
                setFieldValue('blockedCities', addresses);
              } else {
                setAddressesOptions([]);
                setFieldValue('blockedCities', []);
              }
            }}
            errorText={
              formikProps.touched.blockedCities || formikProps.touched.blockCitiesActive
                ? formikProps.errors.blockedCities
                : undefined
            }
          />
        </ExpandableToggle>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_message_to_interpreter_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_message_to_interpreter_description,
          })}
          active={formikProps.values.messageToInterpreterActive}
          action={
            <B_Form.Check
              data-testid="message-to-interpreter-switch"
              type="switch"
              checked={formikProps.values.messageToInterpreterActive}
              onChange={(e) => {
                setFieldValue('messageToInterpreterActive', e.target.checked);
                setFieldValue('messageToInterpreter', undefined);
              }}
            />
          }
        >
          <span className={styles.additionalInfo}>
            <FormattedMessage
              id={translationKeys.create_interpretation_order_settings_message_to_interpreter_text}
            />
          </span>
          <TextField
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_message_to_interpreter_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_settings_message_to_interpreter_placeholder,
            })}
            name="messageToInterpreter"
            value={formikProps.values.messageToInterpreter}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            errorText={
              formikProps.touched.messageToInterpreter ||
              formikProps.touched.messageToInterpreterActive
                ? formikProps.errors.messageToInterpreter
                : undefined
            }
            size="large"
            textArea
            maxLength={1000}
            hint={intl.formatMessage(
              {
                id: translationKeys.create_interpretation_order_settings_message_to_interpreter_hint,
              },
              { charactersLeft: 1000 - (formikProps.values.messageToInterpreter?.length || 0) },
            )}
          />
        </ExpandableToggle>
        <ExpandableToggle
          title={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_upload_file_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_upload_file_description,
          })}
          active={formikProps.values.uploadFilesActive}
          action={
            <B_Form.Check
              data-testid="upload-file-switch"
              type="switch"
              checked={formikProps.values.uploadFilesActive}
              onChange={(e) => {
                setFieldValue('uploadFilesActive', e.target.checked);
                setFieldValue('attachments', []);
              }}
            />
          }
        >
          <span className={styles.additionalInfo}>
            <FormattedMessage
              id={translationKeys.create_interpretation_order_settings_upload_file_text}
            />
          </span>
          <>
            <FileSelector
              selectedFiles={formikProps.values.attachments}
              data-testid="attachments-file-selector"
              multiple
              onSelection={(newFiles) => {
                setFieldValue('attachments', [...formikProps.values.attachments, ...newFiles]);
              }}
            />
            {(formikProps.touched.attachments || formikProps.touched.uploadFilesActive) &&
              formikProps.errors.attachments && (
                <span className={styles.errorMessage}>
                  <span>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  {formikProps.errors.attachments as string}
                </span>
              )}
          </>
          <div className={styles.fileList}>
            {formikProps.values.attachments?.map((file: FileState) => {
              return (
                <React.Fragment key={file.uid}>
                  <DetailItem
                    subtitle={<span>{formatFileSize(file.size)}</span>}
                    title={file.name}
                    preview={
                      file.type.includes('image') ? (
                        <img src={file.preview} height="40" />
                      ) : (
                        <img src={defaultFilePreview} height="40" />
                      )
                    }
                    onActionSelect={(action) => {
                      if (action === 'Download') {
                        handleFileDownload(file);
                      }
                      if (action === 'Delete') {
                        const updatedFiles = formikProps.values.attachments?.filter(
                          (currentFiles) => currentFiles.uid !== file.uid,
                        );

                        setFieldValue('attachments', updatedFiles);
                      }
                    }}
                    actions={[
                      {
                        id: 'Download',
                        text: intl.formatMessage({
                          id: translationKeys.create_translation_order_information_file_download_label,
                        }),
                        icon: faArrowDownToLine,
                      },
                      {
                        id: 'Delete',
                        text: intl.formatMessage({
                          id: translationKeys.create_translation_order_information_file_delete_label,
                        }),
                        icon: faTrash,
                        variant: 'critical',
                      },
                    ]}
                  ></DetailItem>
                </React.Fragment>
              );
            })}
          </div>
        </ExpandableToggle>
        {!isEditForm && (
          <CcExpandableToggle
            active={formikProps.values.ccActive}
            onActiveChange={(checked) => {
              setFieldValue('ccActive', checked);
              setFieldValue('ccEmails', []);
              setFieldValue('confirmationPhones', []);
            }}
            confirmationPhones={formikProps.values.confirmationPhones}
            emails={formikProps.values.ccEmails}
            onAddEmail={(email) => {
              setFieldValue('ccEmails', [...formikProps.values.ccEmails, email]);
            }}
            onAddPhone={(phone) => {
              setFieldValue('confirmationPhones', [
                ...formikProps.values.confirmationPhones,
                phone,
              ]);
            }}
            onDeleteEmail={(email) => {
              setFieldValue(
                'ccEmails',
                formikProps.values.ccEmails.filter((item) => item !== email),
              );
            }}
            onDeletePhone={(phone) => {
              setFieldValue(
                'confirmationPhones',
                formikProps.values.confirmationPhones.filter((item) => item !== phone),
              );
            }}
            isErrorVisible={
              (formikProps.touched.ccActive || formikProps.touched.ccEmails) &&
              !!formikProps.errors.ccEmails
            }
          />
        )}
      </div>
    </CreateOrderSectionContainer>
  );
};

export default SettingsSection;
