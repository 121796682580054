enum CustomersFilterField {
  Id = 'id',
  Type = 'type',
  Status = 'status',
  NameEmailPhone = 'nameEmailPhone',
  OrgNumberName = 'orgNumberName',
  Departments = 'departments',
}

export default CustomersFilterField;
