import React, { useState } from 'react';
import B_Dropdown from 'react-bootstrap/Dropdown';
import { createIntl } from 'react-intl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classNames from 'classnames';
import classnames from 'classnames';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Dropdown.module.scss';

export type DropdownItemVariant = 'critical' | 'inactive';

export interface DropdownItem {
  id: string;
  text: string;
  customIcon?: React.ReactNode;
  icon?: IconDefinition;
  iconClassName?: string;
  disabled?: boolean;
  variant?: DropdownItemVariant;
  tooltip?: string;
  className?: any;
  'data-testId'?: string;
}

interface Props {
  toggle: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  items: DropdownItem[];
  label?: string;
  disabled?: boolean;
  selectedItemId?: string;
  onSelect?: (selection: string | null) => void;
  'data-testid'?: string;
  hideClearSelected?: boolean;
}

const Dropdown = ({
  toggle,
  items,
  selectedItemId,
  disabled,
  onSelect,
  label,
  ['data-testid']: dataTestid,
  hideClearSelected,
}: Props) => {
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClearSelection = () => {
    setDropdownOpen(false);
    handleSelectionChange(null);
  };

  const handleSelectionChange = (eventKey: string | null) => {
    if (onSelect) {
      onSelect(eventKey);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const hasSelection = selectedItemId && !hideClearSelected;

  return (
    <B_Dropdown
      onSelect={handleSelectionChange}
      onToggle={!disabled ? handleDropdownToggle : undefined}
      defaultValue={selectedItemId}
      show={dropdownOpen}
    >
      {label && (
        <span data-testid="dropdown-label" className={styles.label}>
          {label}
        </span>
      )}

      <B_Dropdown.Toggle
        className={styles.dropdownToggle}
        data-testid="dropdown-toggle"
        as="div"
        disabled={disabled}
      >
        {typeof toggle === 'function' ? toggle(dropdownOpen) : toggle}
      </B_Dropdown.Toggle>

      <B_Dropdown.Menu
        className={classnames(styles.dropdownMenu, { [styles.hasSelection]: hasSelection })}
        data-testid={dataTestid}
      >
        {items.map((item) => (
          <Tooltip title={item.tooltip} key={item.id}>
            <B_Dropdown.Item
              className={classNames(styles.dropDownItem, item.className, {
                [styles.critical]: item.variant === 'critical',
                [styles.inactive]: item.disabled,
              })}
              eventKey={item.id}
              active={item.id === selectedItemId}
              data-testid={item['data-testId'] || 'dropdown-item'}
              disabled={item.disabled}
            >
              {item.customIcon && (
                <div
                  className={classNames(
                    styles.customIcon,
                    item.iconClassName,
                    item.variant && styles[item.variant],
                    item.disabled && styles.inactive,
                  )}
                >
                  {item.customIcon}
                </div>
              )}
              {item.icon && (
                <FontAwesomeIcon
                  icon={item.icon}
                  className={classNames(
                    item.iconClassName,
                    item.variant && styles[item.variant],
                    item.disabled && styles.inactive,
                  )}
                  data-testid="dropdown-item-icon"
                />
              )}
              <span>{item.text}</span>
            </B_Dropdown.Item>
          </Tooltip>
        ))}
        {hasSelection && (
          <div className={styles.clearSelection}>
            <Button
              variant="transparent"
              size="medium"
              onClick={handleClearSelection}
              data-testid="dropdown-clear-button"
            >
              {intl.formatMessage({ id: translationKeys.dropdown_menu_clear_selected })}
            </Button>
          </div>
        )}
      </B_Dropdown.Menu>
    </B_Dropdown>
  );
};

export default Dropdown;
