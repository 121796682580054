import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../../Api';
import { dayRegex, emailRegex, monthRegex, phoneRegex } from '../../helpers/regexPatterns';
import useDebounce from '../../hooks/useDebounce';
import NewSkillDrawer from '../../InterpretationSkills/Drawers/NewSkillDrawer/NewSkillDrawer';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import CreateSupplierSuccess from './CreateSupplierSuccess/CreateSupplierSuccess';
import styles from './CreateSupplierDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  day: string;
  month: string;
  year: string;
}

// TODO: Why isn't this in the types?
export interface CreateSupplierResponse {
  supplier?: { id: number; [key: string]: any };
}

const CreateSupplierDrawer = (props: Props) => {
  const { show, onClose, onComplete } = props;
  const api = useApi();
  const [createSupplierError, setCreateSupplierError] = useState<ApiError | null>(null);
  const [createSupplierLoading, setCreateSupplierLoading] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const [sendPassword, setSendPassword] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [godmodeUrl, setGodmodeUrl] = useState<string | undefined>('');
  const [selectedGender, setSelectedGender] = useState('');
  const [addressResponse, setAddressResponse] = useState<any[]>([]);
  const [addressesResults, setAddressesResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<SearchDropdownMenuOption>();
  const [apiErrors, setApiErrors] = useState('');
  const [showNewSkillDrawer, setShowNewSkillDrawer] = useState(false);

  const resetShowSuccess = () => setShowSuccess(false);

  const debounceAddressSearch = useDebounce(300);

  const intl = useIntl();

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_create_supplier_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_create_supplier_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_create_supplier_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_create_supplier_email_label,
            }),
          },
        ),
      ),
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_create_supplier_phone_label,
            }),
          },
        ),
      )
      .notRequired(),
    day: yup
      .string()
      .matches(
        dayRegex,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_day_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_day_error_message,
            }),
          },
        ),
      )
      .min(1)
      .max(31),
    month: yup
      .string()
      .matches(
        monthRegex,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_month_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_month_error_message,
            }),
          },
        ),
      )
      .min(1)
      .max(12),
    year: yup
      .number()
      .min(
        1923,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_year_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_year_error_message,
            }),
          },
        ),
      )
      .max(
        new Date().getFullYear(),
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_max_year_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_max_year_error_message,
            }),
            year: new Date().getFullYear(),
          },
        ),
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    const { day, month, year, firstName, lastName, phoneNumber, email } = values;
    const birth = day && month && year ? `${day}.${month}.${year}` : '';

    const payload = {
      supplier: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneCode: selectedPhoneCode?.key,
        phoneNumber: phoneNumber,
        email: email,
        birth: birth,
        sex: selectedGender,
      },
      address: undefined as Record<string, string> | undefined,
      generateAndSendPassword: sendPassword,
    };

    if (selectedAddress) {
      const address = addressResponse.find(
        (address) => address.data.place_id == selectedAddress.key,
      );

      if (address) {
        const addressValue = {
          line1: address.data.formatted_address.split(',')[0],
          city:
            address.data.address_components.find((component: any) =>
              component.types.includes('postal_town'),
            )?.long_name || undefined,
          county:
            address.data.address_components.find((component: any) =>
              component.types.includes('political'),
            )?.long_name || undefined,
          country:
            address.data.address_components.find((component: any) =>
              component.types.includes('country'),
            )?.long_name || undefined,
          postcode:
            address.data.address_components.find((component: any) =>
              component.types.includes('postal_code'),
            )?.long_name || undefined,
          latitude: address.data.geometry.location.lat,
          longitude: address.data.geometry.location.lng,
        };
        payload.address = addressValue;
      }
    }

    const { data, error } = await api.createSupplier(payload, setCreateSupplierLoading);

    if (data && data.supplier) {
      setSupplierId(data.supplier.id);
      setShowSuccess(true);
      setSendPassword(true);
    }

    if (error) setCreateSupplierError(error);
  };

  const displayNewSkillDrawer = () => {
    setShowNewSkillDrawer(true);
  };

  useEffect(() => {
    if (createSupplierError) {
      const errorText = createSupplierError.text[0];
      setApiErrors(errorText);
    } else {
      setApiErrors('');
    }
  }, [createSupplierError]);

  const getSuppliers = async () => {
    const { data } = await api.getSuppliers();

    if (data && data.suppliers) {
      const suppliers = data.suppliers;

      const createdSupplier = suppliers.find((supplier) => supplier.id === supplierId);

      if (createdSupplier) {
        setGodmodeUrl(createdSupplier.godmodeSupplierUrl);
      }
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [supplierId]);

  const getAddressList = async (query: string) => {
    const { data } = await api.getAddressList({ query });

    if (data) {
      setAddressResponse(data);
      const addressOptions =
        data.map((address: any, index: any) => {
          return {
            id: index,
            label: address.data.formatted_address,
            key: address.data.place_id,
          };
        }) || [];

      setAddressesResults(addressOptions);
    }
  };

  const clearForm = () => {
    setSelectedGender('');
  };

  return (
    <Drawer
      show={show}
      onClose={() => {
        onClose();
        resetShowSuccess();
        setSelectedGender('');
        setApiErrors('');
        setShowNewSkillDrawer(false);
      }}
      title={
        showSuccess
          ? intl.formatMessage({ id: translationKeys.suppliers_page_create_supplier_success_title })
          : intl.formatMessage({ id: translationKeys.suppliers_page_create_supplier_title })
      }
      data-testid="create-supplier-drawer"
    >
      {showSuccess ? (
        <CreateSupplierSuccess
          onClose={onClose}
          onComplete={onComplete}
          resetShowSuccess={resetShowSuccess}
          fullName={firstName + ' ' + lastName}
          godmodeUrl={godmodeUrl}
          onOpenNewSkillDrawer={displayNewSkillDrawer}
        />
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            day: '',
            month: '',
            year: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            dirty,
            handleReset,
            errors,
          }) => (
            <Form onSubmit={handleSubmit} className={styles.container}>
              <div className={styles.nameInputs}>
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_first_name_placeholder,
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_first_name_label,
                  })}
                  size="large"
                  name="firstName"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    setFirstName(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.firstName}
                  errorText={touched.firstName ? errors.firstName : undefined}
                  data-testid="first-name-input"
                />

                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_last_name_placeholder,
                  })}
                  type="text"
                  required
                  label={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_last_name_label,
                  })}
                  size="large"
                  name="lastName"
                  onChange={(e) => {
                    handleChange(e);
                    setLastName(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.lastName}
                  errorText={touched.lastName ? errors.lastName : undefined}
                  data-testid="last-name-input"
                />
              </div>
              <div>
                <p className={styles.dateOfBirthLabel}>
                  <FormattedMessage id={translationKeys.create_supplier_drawer_dob_label} />
                </p>
                <div className={styles.dateOfBirthInputs}>
                  <TextField
                    placeholder="DD"
                    type="text"
                    size="large"
                    name="day"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.day}
                    maxLength={2}
                    errorText={touched.day ? errors.day : undefined}
                    data-testid="day-input-field"
                  />
                  <TextField
                    placeholder="MM"
                    type="text"
                    size="large"
                    name="month"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.month}
                    maxLength={2}
                    errorText={touched.month ? errors.month : undefined}
                    data-testid="month-input-field"
                  />
                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_supplier_drawer_dob_year_placeholder,
                    })}
                    type="text"
                    required
                    size="large"
                    name="year"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.year}
                    maxLength={4}
                    errorText={touched.year ? errors.year : undefined}
                    data-testid="year-input-field"
                  />
                </div>
              </div>
              <div>
                <p className={styles.genderLabel}>
                  <FormattedMessage id={translationKeys.create_supplier_drawer_gender_label} />
                </p>
                <div className={styles.genderRadioBtns}>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({
                      id: translationKeys.create_supplier_drawer_gender_male_label,
                    })}
                    name="gender"
                    checked={selectedGender === 'male'}
                    onChange={() => {
                      setSelectedGender('male');
                    }}
                    data-testid="male-radio-option"
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({
                      id: translationKeys.create_supplier_drawer_gender_female_label,
                    })}
                    name="gender"
                    checked={selectedGender === 'female'}
                    onChange={() => {
                      setSelectedGender('female');
                    }}
                    data-testid="female-radio-option"
                  />
                </div>
              </div>

              <SearchDropdown
                options={addressesResults}
                search
                searchAddress
                size="large"
                label={intl.formatMessage({
                  id: translationKeys.create_supplier_drawer_address_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_supplier_drawer_address_placeholder,
                })}
                onSearch={(query: string) => {
                  debounceAddressSearch(() => {
                    getAddressList(query);
                  });
                }}
                onChange={(address) => {
                  if (address && address.length > 0 && address[0].key) {
                    setSelectedAddress(address[0]);
                  } else {
                    setSelectedAddress(undefined);
                  }
                }}
                data-testid="create-supplier-address-input"
              />

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_create_supplier_email_placeholder,
                })}
                type="email"
                label={intl.formatMessage({
                  id: translationKeys.suppliers_page_create_supplier_email_label,
                })}
                size="large"
                required
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errorText={touched.email ? errors.email : undefined}
                data-testid="email-input"
              />

              <div className={styles.phoneInputs}>
                <div data-testid="phone-code-dropdown">
                  <PhoneCodeDropdown
                    selected={selectedPhoneCode}
                    placeholder="+47"
                    label={intl.formatMessage({
                      id: translationKeys.suppliers_page_create_supplier_country_code_label,
                    })}
                    onChange={(phoneCodes) => {
                      if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                        setSelectedPhoneCode(phoneCodes[0]);
                      }
                    }}
                  />
                </div>

                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_phone_placeholder,
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: translationKeys.suppliers_page_create_supplier_phone_label,
                  })}
                  size="large"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
                  data-testid="phone-number-input"
                />
              </div>

              <Form.Check
                type="checkbox"
                label={intl.formatMessage({
                  id: translationKeys.suppliers_page_create_supplier_send_password_label,
                })}
                checked={sendPassword}
                onChange={(e) => setSendPassword(e.currentTarget.checked)}
                data-testid="check-box-password"
              />

              {apiErrors && <Banner variant="error" text={apiErrors} />}
              <div className={styles.actions}>
                <Button
                  size="large"
                  variant="gray"
                  onClick={() => {
                    handleReset();
                    clearForm();
                  }}
                  disabled={!dirty}
                  data-testid="clear-button"
                >
                  <FormattedMessage id={translationKeys.create_supplier_drawer_clear_button} />
                </Button>

                <Button
                  size="large"
                  type="submit"
                  isLoading={createSupplierLoading}
                  data-testid="submit-button"
                >
                  <FormattedMessage id={translationKeys.create_supplier_drawer_create_button} />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {showNewSkillDrawer && (
        <NewSkillDrawer
          supplierId={supplierId}
          onClose={() => {
            setShowNewSkillDrawer(false);
            onClose();
            resetShowSuccess();
            setSelectedGender('');
          }}
          show
        />
      )}
    </Drawer>
  );
};

export default CreateSupplierDrawer;
