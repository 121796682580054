import TranslationSkillsFilterField from '../TranslationSkillsFilterField';

const getTranslationSkillsFilters = (
  currentFilters: Record<string, string | string[]>,
  field: TranslationSkillsFilterField,
  value: string | string[],
): Record<string, string | string[]> => {
  if (field === TranslationSkillsFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === TranslationSkillsFilterField.Name) {
    if (Array.isArray(value)) return currentFilters;
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[lookup_fields_cont]': filterValue } };
  }

  if (field === TranslationSkillsFilterField.Languages) {
    if (Array.isArray(value) && value.length >= 0) {
      return { ...currentFilters, ...{ 's[lang_id_in]': value } };
    }

    return currentFilters;
  }

  if (field === TranslationSkillsFilterField.Qualifications) {
    if (Array.isArray(value) && value.length >= 0) {
      return { ...currentFilters, ...{ 's[qualification_id_in]': value } };
    }

    return currentFilters;
  }

  if (field === TranslationSkillsFilterField.CreatedAt) {
    return {
      ...currentFilters,
      ...{ 's[created_at_gteq]': value[0], 's[created_at_lteq]': value[1] },
    };
  }

  if (field === TranslationSkillsFilterField.AccountStatuses) {
    return {
      ...currentFilters,
      ...{ 's[account_status_name_in]': value },
    };
  }

  return currentFilters;
};

export default getTranslationSkillsFilters;
