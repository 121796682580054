import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Pagination } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsInteger,
  parseAsString,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetEnterprisesQuery } from '../Api/Endpoints/Enterprises/Enterprises.hooks';
import useDebounce from '../hooks/useDebounce';
import translationKeys from '../translations/translationKeys';
import { ManagerEnterprise } from '../types';
import EnterprisesTable from './EnterprisesTable/EnterprisesTable';
import getEnterpriseFilters from './utils/getEnterpriseFilters';
import EnterprisesFilterField from './EnterprisesFilterField';
import styles from './Enterprises.module.scss';

export type EnterprisesFilters = Values<{
  id: ParserBuilder<number>;
  orgNumber: ParserBuilder<number>;
  orgName: ParserBuilder<string>;
  owner: ParserBuilder<string>;
}>;

const Enterprises = () => {
  const [enterprises, setEnterprises] = useState<ManagerEnterprise[]>([]);
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    id: parseAsInteger,
    orgNumber: parseAsInteger,
    orgName: parseAsString,
    owner: parseAsString,
  });
  const [parsedFilters, setParsedFilters] = useState<Record<string, string>>(
    Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getEnterpriseFilters(acc, key as EnterprisesFilterField, value as string);
      }
      return acc;
    }, {}),
  );

  const {
    data: enterprisesData,
    isLoading: isEnterprisesLoading,
    error: enterprisesError,
    refetch,
  } = useGetEnterprisesQuery({
    page,
    's[s]': 'id desc',
    ...stripObject(parsedFilters),
  });
  const showLoadMoreButton =
    !isEnterprisesLoading && enterprises && enterprisesData && page < enterprisesData.pages;

  const handleFilterChange = (field: EnterprisesFilterField, value: string) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });
    debounceFilterChange(() => {
      setParsedFilters(getEnterpriseFilters(parsedFilters, field, value));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  useEffect(() => {
    if (!enterprisesData) return;

    const appendData = enterprisesData.page > 1;

    setEnterprises(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...enterprisesData.enterprises]
        : enterprisesData.enterprises,
    );
    setIsLoadMoreTriggered(false);
  }, [enterprisesData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 data-testid="enterprises-header">
          <FormattedMessage id={translationKeys.enterprises_page_title} />
        </h1>
      </div>

      <div className={styles.tableContainer}>
        <EnterprisesTable
          enterprises={enterprises}
          error={enterprisesError}
          isLoading={isEnterprisesLoading}
          isLoadMoreTriggered={isLoadMoreTriggered}
          filters={filters}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
        />
      </div>

      {showLoadMoreButton && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              setIsLoadMoreTriggered(true);
              handleLoadMore();
            }}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.enterprises_page_load_more_button} />
          </Button>
        </div>
      )}

      {!isEnterprisesLoading && !!enterprises.length && (
        <div>
          <Pagination
            currentPage={page}
            totalPages={enterprisesData?.pages || 1}
            setPage={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

export default Enterprises;
