import React from 'react';
import { createIntl } from 'react-intl';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classNames from 'classnames';
import Button from '../Button/Button';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Pagination.module.scss';

interface Props {
  currentPage: number;
  totalPages: number;
  showPages?: number;
  setPage: (page: number) => void;
  className?: string;
}

type Position = 'start' | 'center' | 'end';

const Pagination = ({ currentPage, totalPages, showPages = 3, setPage, className }: Props) => {
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  if (currentPage > totalPages) {
    // eslint-disable-next-line no-console
    console.error('Current page is greater than total pages');
  }

  const getPosition = () => {
    if (currentPage <= showPages) {
      return 'start';
    }
    if (currentPage > totalPages - showPages) {
      return 'end';
    }
    return 'center';
  };

  const renderPages = (position: Position) => {
    switch (position) {
      case 'start': {
        const pages = Math.min(showPages + 1, totalPages);
        return (
          <>
            {[...Array(pages)].map((_, i) => {
              const page = i + 1;
              return (
                <Button
                  size="large"
                  key={i}
                  variant={page === currentPage ? 'primary' : 'gray'}
                  className={styles.pageButton}
                  onClick={() => setPage(page)}
                >
                  {page}
                </Button>
              );
            })}
            {totalPages > showPages + 2 && <span className={styles.ellipsis}>...</span>}
            {totalPages > showPages + 1 && (
              <Button
                className={styles.pageButton}
                size="large"
                variant="gray"
                onClick={() => setPage(totalPages)}
              >
                {totalPages}
              </Button>
            )}
          </>
        );
      }
      case 'end': {
        return (
          <>
            {totalPages > showPages + 1 && (
              <Button
                className={styles.pageButton}
                size="large"
                variant="gray"
                onClick={() => setPage(1)}
              >
                1
              </Button>
            )}
            {totalPages > showPages + 1 && <span className={styles.ellipsis}>...</span>}
            {[...Array(showPages + 1)].map((_, i) => {
              const page = totalPages - showPages + i;
              return (
                <Button
                  className={styles.pageButton}
                  size="large"
                  key={i}
                  variant={page === currentPage ? 'primary' : 'gray'}
                  onClick={() => setPage(page)}
                >
                  {page}
                </Button>
              );
            })}
          </>
        );
      }
      case 'center': {
        return (
          <>
            <Button
              className={styles.pageButton}
              size="large"
              variant="gray"
              onClick={() => setPage(1)}
            >
              1
            </Button>
            <span className={styles.ellipsis}>...</span>
            {[...Array(showPages)].map((_, i) => {
              const page = currentPage - Math.floor(showPages / 2) + i;
              return (
                <Button
                  className={styles.pageButton}
                  size="large"
                  key={i}
                  variant={page === currentPage ? 'primary' : 'gray'}
                  onClick={() => setPage(page)}
                >
                  {page}
                </Button>
              );
            })}
            <span className={styles.ellipsis}>...</span>
            <Button
              className={styles.pageButton}
              size="large"
              variant="gray"
              onClick={() => setPage(totalPages)}
            >
              {totalPages}
            </Button>
          </>
        );
      }
    }
  };

  return (
    <div className={classNames(styles.pagination, className)}>
      <Button
        size="large"
        variant="gray"
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        className={styles.previousNextButton}
        disabled={currentPage === 1}
        onClick={() => setPage(currentPage - 1)}
      >
        {intl.formatMessage({ id: translationKeys.pagination_previous_button })}
      </Button>
      <div className={styles.pages}>{renderPages(getPosition())}</div>
      <Button
        size="large"
        variant="gray"
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        iconPosition="right"
        className={styles.previousNextButton}
        disabled={currentPage === totalPages}
        onClick={() => setPage(currentPage + 1)}
      >
        {intl.formatMessage({ id: translationKeys.pagination_next_button })}
      </Button>
    </div>
  );
};

export default Pagination;
