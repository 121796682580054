import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import { MegaMenu } from '@skiwo/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import connectLogo from './assets/connect-logo.svg';
import salitaLogo from './assets/salita-logo.svg';
import CreateInterpretationOrder from './CreateInterpretationOrder/CreateInterpretationOrder';
import CreateTranslationOrder from './CreateTranslationOrder/CreateTranslationOrder';
import Customers from './Customers/Customers';
import Enterprises from './Enterprises/Enterprises';
import urls from './helpers/routes';
import routes from './helpers/routes';
import useClarity from './hooks/useClarity';
import useGTM from './hooks/useGoogleTagManager';
import useHotjar from './hooks/useHotjar';
import InterpretationSkills from './InterpretationSkills/InterpretationSkills';
import Job from './Job/Job';
import Jobs from './Jobs/Jobs';
import AssignmentSearch from './MenuContent/AssignmentSearch/AssignmentSearch';
import CreateDropdown from './MenuContent/CreateDropdown/CreateDropdown';
import LanguagesDropdown from './MenuContent/LanguagesDropdown/LanguagesDropdown';
import SettingsDropdown from './MenuContent/SettingsDropdown/SettingsDropdown';
import { PageNotFound } from './PageNotFound/PageNotFound';
import { useAuth } from './providers/AuthProvider';
import { useFeatureToggle } from './providers/FeatureToggleProvider';
import JobCategoriesProvider from './providers/JobCategoriesProvider';
import LanguagesProvider from './providers/LanguagesProvider';
import QualificationsProvider from './providers/QualificationsProvider';
import ToastProvider from './providers/ToastProvider/ToastProvider';
import Suppliers from './Suppliers/Suppliers';
import TranslationOrder from './TranslationOrder/TranslationOrder';
import TranslationSkills from './TranslationSkills/TranslationSkills';
import TranslationSubtasks from './TranslationSubtasks/TranslationSubtasks';
import { CheckEmail, ForgotPassword, LogIn, SetNewPassword } from './authentication';
import getMenuTabs from './MenuContent';
import { Feature } from './types';
import styles from './App.module.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error.code === 404) return false;
        if (failureCount < 2) return true;
        return false;
      },
    },
  },
});

const Menu = () => {
  const { isAuthenticated } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const handleOnclick = (link: string) => {
    navigate(link);
  };
  const menuTabs = getMenuTabs(intl);

  const getMenuLogo = () => {
    if (process.env.REACT_APP_THEME === 'salita') {
      return salitaLogo;
    }

    return connectLogo;
  };

  return (
    <MegaMenu
      menuTabs={menuTabs}
      isAuthenticated={isAuthenticated()}
      logo={<img src={getMenuLogo()} />}
      onClick={handleOnclick}
      actions={<CreateDropdown />}
      rightMenu={
        <>
          <AssignmentSearch />
          <LanguagesDropdown />
          <SettingsDropdown />
        </>
      }
    />
  );
};

function App() {
  const { isAuthenticated } = useAuth();
  const { isFeatureActive } = useFeatureToggle();
  useHotjar();
  useClarity();
  useGTM();

  const ProtectedRoute = () => {
    if (!isAuthenticated()) {
      return <Navigate to={routes.login} replace />;
    }

    return (
      <div className={styles.content}>
        <Outlet />
      </div>
    );
  };

  const LoggedOutPath = () => {
    if (isAuthenticated()) {
      return <Navigate to={routes.jobs} replace />;
    }

    return <Outlet />;
  };

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    favicon.href = '/connect-favicon.ico';

    if (process.env.REACT_APP_THEME === 'salita') {
      favicon.href = '/salita-favicon.ico';
      document.title = 'Manage Salita';
    } else {
      favicon.href = '/connect-favicon.ico';
      document.title = 'Skiwo Connect';
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <LanguagesProvider>
        <JobCategoriesProvider>
          <ToastProvider>
            <NuqsAdapter>
              <RouterProvider
                router={createBrowserRouter(
                  createRoutesFromElements(
                    <Route
                      element={
                        <>
                          <Menu />
                          <Outlet />
                        </>
                      }
                    >
                      <Route element={<LoggedOutPath />}>
                        <Route path="/" element={<Navigate to={routes.login} replace />} />
                        <Route path={routes.login} element={<LogIn />} />
                        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
                        <Route path={routes.checkEmail} element={<CheckEmail />} />
                        <Route path={routes.setNewPassword} element={<SetNewPassword />} />
                      </Route>

                      {/* Protected routes */}
                      <Route element={<ProtectedRoute />}>
                        <Route path={routes.jobs} element={<Jobs />} />
                        <Route path={routes.customers} element={<Customers />} />
                        <Route
                          path={routes.suppliers}
                          element={
                            <QualificationsProvider>
                              <Suppliers />
                            </QualificationsProvider>
                          }
                        />
                        <Route path={routes.enterprises} element={<Enterprises />} />
                        <Route
                          path={routes.translationSubtasks}
                          element={
                            <QualificationsProvider>
                              <TranslationSubtasks />
                            </QualificationsProvider>
                          }
                        />
                        <Route
                          path={`${routes.translationSubtasks}/:orderId`}
                          element={<TranslationOrder />}
                        />
                        {isFeatureActive(Feature.CreateInterpretationOrder) === null ? (
                          <Route path={urls.createInterpretationOrder} element={null} />
                        ) : (
                          isFeatureActive(Feature.CreateInterpretationOrder) && (
                            <Route
                              path={urls.createInterpretationOrder}
                              element={
                                <QualificationsProvider>
                                  <CreateInterpretationOrder />
                                </QualificationsProvider>
                              }
                            />
                          )
                        )}

                        {isFeatureActive(Feature.CreateTranslationOrder) === null ? (
                          <Route path={urls.createTranslationOrder} element={null} />
                        ) : (
                          isFeatureActive(Feature.CreateTranslationOrder) && (
                            <Route
                              path={urls.createTranslationOrder}
                              element={<CreateTranslationOrder />}
                            />
                          )
                        )}

                        {isFeatureActive(Feature.Interpretation) === null ? (
                          <Route path={`${routes.jobs}/:jobId`} element={null} />
                        ) : (
                          isFeatureActive(Feature.Interpretation) && (
                            <Route
                              path={`${routes.jobs}/:jobId`}
                              element={
                                <QualificationsProvider>
                                  <Job />
                                </QualificationsProvider>
                              }
                            />
                          )
                        )}

                        <Route
                          path={routes.interpretationSkills}
                          element={
                            <QualificationsProvider>
                              <InterpretationSkills />
                            </QualificationsProvider>
                          }
                        />
                        <Route
                          path={routes.translationSkills}
                          element={
                            <QualificationsProvider>
                              <TranslationSkills />
                            </QualificationsProvider>
                          }
                        />

                        <Route path="*" element={<PageNotFound />} />
                      </Route>
                    </Route>,
                  ),
                )}
              />
            </NuqsAdapter>
          </ToastProvider>
        </JobCategoriesProvider>
      </LanguagesProvider>
    </QueryClientProvider>
  );
}

export default App;
