enum TranslationSubtasksFilterField {
  Id = 'id',
  OrderId = 'orderId',
  Document = 'document',
  Status = 'status',
  CustomerInput = 'customerInput',
  Owner = 'owner',
  Languages = 'languages',
  ExternalDeadline = 'externalDeadline',
  InternalDeadline = 'internalDeadline',
  CreatedAt = 'createdAt',
  Translator = 'translator',
  WordCount = 'wordCount',
}

export default TranslationSubtasksFilterField;
