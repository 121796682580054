enum InterpretationSkillsFilterField {
  Id = 'id',
  Name = 'name',
  Languages = 'languages',
  Qualifications = 'qualifications',
  CreatedAt = 'createdAt',
  Gender = 'gender',
  Location = 'location',
  Standby = 'standby',
  AccountStatuses = 'accountStatuses',
}

export default InterpretationSkillsFilterField;
