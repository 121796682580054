enum JobsFilterField {
  BuyerAndOrderRef = 'buyerAndOrderRef',
  CategoryAndSubject = 'categoryAndSubject',
  CreatedDate = 'createdDate',
  Id = 'id',
  Interpreter = 'interpreter',
  Language = 'language',
  OwnerAndParticipants = 'ownerAndParticipants',
  Status = 'status',
  StartDate = 'startDate',
  SessionType = 'sessionType',
  Withdrawal = 'withdrawal',
  QualificationRequested = 'qualificationRequested',
  InviteStatus = 'inviteStatus',
  FinancialStatus = 'financialStatus',
  NeedsManualApproval = 'needsManualApproval',
  PaymentMethod = 'paymentMethod',
  CustomerTemplate = 'customerTemplate',
  InterpreterTemplate = 'interpreterTemplate',
}

export default JobsFilterField;
