import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToLine, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, DatePicker, Drawer, Pagination, TextField } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { addDays, endOfDay, format, formatISO } from 'date-fns';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetJobsQuery } from '../Api/Endpoints/Jobs/Jobs.hooks';
import { LockExclamationIcon } from '../assets/icons/LockExclamationIcon';
import useDebounce from '../hooks/useDebounce';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import {
  ManagerJob,
  ManagerJobInviteStatus,
  ManagerJobSessionType,
  ManagerJobStatus,
} from '../types';
import { ManagerJobFinanceStatus } from '../types/ManagerJob';
import JobsTable from './JobsTable/JobsTable';
import JobsTabs from './JobsTabs/JobsTabs';
import getEndOfNextWorkingDay from './utils/getEndOfNextWorkingDay';
import getJobFilters from './utils/getJobFilters';
import JobsFilterField from './JobsFilterField';
import JobsTab from './JobsTab';
import styles from './Jobs.module.scss';

export type JobsFilters = Values<{
  tab: ParserBuilder<JobsTab>;
  id: ParserBuilder<number>;
  startDate: ParserBuilder<string[]>;
  language: ParserBuilder<string[]>;
  sessionType: ParserBuilder<ManagerJobSessionType>;
  inviteStatus: ParserBuilder<ManagerJobInviteStatus>;
  withdrawal: ParserBuilder<string>;
  qualificationRequested: ParserBuilder<string>;
  needsManualApproval: ParserBuilder<string>;
  createdDate: ParserBuilder<string[]>;
  buyerAndOrderRef: ParserBuilder<string>;
  categoryAndSubject: ParserBuilder<string>;
  interpreter: ParserBuilder<string>;
  ownerAndParticipants: ParserBuilder<string>;
  status: ParserBuilder<ManagerJobStatus>;
  financialStatus: ParserBuilder<ManagerJobFinanceStatus>;
  paymentMethod: ParserBuilder<string>;
  customerTemplate: ParserBuilder<string[]>;
  interpreterTemplate: ParserBuilder<string[]>;
}>;

interface TabItem {
  active?: boolean;
  icon?: React.ReactNode;
  count?: number;
  id: JobsTab;
  title: string;
  filters: Record<string, string>;
}

type Filters = Record<string, string | string[]>;

const getTabFilters = (tabItems: TabItem[]) => {
  const activeTabItem = tabItems.find((item) => item.active);

  return activeTabItem ? activeTabItem.filters : {};
};

const Jobs = () => {
  const intl = useIntl();
  const api = useApi();
  const [jobs, setJobs] = useState<ManagerJob[]>([]);
  const [jobStatsLoading, setJobStatsLoading] = useState(false);
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    tab: parseAsStringEnum<JobsTab>(Object.values(JobsTab)),
    id: parseAsInteger,
    startDate: parseAsArrayOf(parseAsString),
    language: parseAsArrayOf(parseAsString),
    sessionType: parseAsStringEnum<ManagerJobSessionType>(Object.values(ManagerJobSessionType)),
    inviteStatus: parseAsStringEnum<ManagerJobInviteStatus>(Object.values(ManagerJobInviteStatus)),
    withdrawal: parseAsString,
    qualificationRequested: parseAsString,
    needsManualApproval: parseAsString,
    createdDate: parseAsArrayOf(parseAsString),
    buyerAndOrderRef: parseAsString,
    categoryAndSubject: parseAsString,
    interpreter: parseAsString,
    ownerAndParticipants: parseAsString,
    status: parseAsStringEnum<ManagerJobStatus>(Object.values(ManagerJobStatus)),
    financialStatus: parseAsStringEnum<ManagerJobFinanceStatus>(
      Object.values(ManagerJobFinanceStatus),
    ),
    paymentMethod: parseAsString,
    customerTemplate: parseAsArrayOf(parseAsString),
    interpreterTemplate: parseAsArrayOf(parseAsString),
  });
  const [activeTab, setActiveTab] = useState(filters.tab || JobsTab.Urgent);
  const [showExportDrawer, setShowExportDrawer] = useState(false);
  const [showExportDatePicker, setShowExportDatePicker] = useState(false);
  const [exportDatePeriod, setExportDatePeriod] = useState<Date[]>([]);
  const currentTime = new Date();
  const endOfToday = endOfDay(currentTime);
  const endOfTomorrow = endOfDay(addDays(currentTime, 1));
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const [tabItems, setTabItems] = useState<TabItem[]>([
    {
      id: JobsTab.Urgent,
      title: intl.formatMessage({ id: translationKeys.jobs_page_urgent_tab }),
      filters: {
        's[urgent_jobs_for_connect]': 'true',
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.NextUp,
      title: intl.formatMessage({ id: translationKeys.jobs_page_in_progress_tab }),
      filters: {
        's[status_eq]': 'published',
        's[confirmation_deadline_gt]': formatISO(endOfToday),
        's[confirmation_deadline_lteq]': formatISO(getEndOfNextWorkingDay()),
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.OtherPublished,
      title: intl.formatMessage({ id: translationKeys.jobs_page_other_published_tab }),
      filters: {
        's[status_eq]': 'published',
        's[confirmation_deadline_gteq]': formatISO(endOfTomorrow),
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.Vip,
      title: intl.formatMessage({ id: translationKeys.jobs_page_vip_tab }),
      filters: {
        's[status_eq]': 'published',
        's[vip_template_eq]': 'true',
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.All,
      title: intl.formatMessage({ id: translationKeys.jobs_page_all_tab }),
      filters: {
        's[s]': 'id desc',
      },
    },
    {
      id: JobsTab.Risky,
      title: intl.formatMessage({ id: translationKeys.jobs_page_risky_tab }),
      icon: <FontAwesomeIcon icon={faExclamationCircle} />,
      count: 0,
      filters: {
        's[finance_risky]': '1',
      },
    },
    {
      id: JobsTab.Stuck,
      title: intl.formatMessage({ id: translationKeys.jobs_page_stuck_tab }),
      icon: <LockExclamationIcon />,
      count: 0,
      filters: {
        's[finance_stuck]': '1',
      },
    },
  ]);

  const [parsedFilters, setParsedFilters] = useState<Filters>({
    ...(tabItems.find((item) => item.id === activeTab)?.filters || {}),
    ...Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getJobFilters(acc, key as JobsFilterField, value as string);
      }
      return acc;
    }, {}),
  });

  const [topFilters, setTopFilters] = useState(getTabFilters(tabItems));

  const {
    data: jobsData,
    isLoading: isJobsLoading,
    error: jobsError,
  } = useGetJobsQuery({
    page,
    items: 30,
    ...stripObject(parsedFilters),
    ...stripObject(topFilters),
  });
  const showLoadMoreButton = !isJobsLoading && jobs && jobsData && page < jobsData.pages;

  const fetchJobStats = async () => {
    const { data } = await api.getJobsStats(setJobStatsLoading);
    if (data) {
      setTabItems((prev) =>
        prev.map((item) => ({
          ...item,
          count: data[item.id] ? parseInt(data[item.id]) : undefined,
        })),
      );
    }
  };

  const handleTabSelect = (newActiveTab: JobsTab) => {
    // Reset filters on tab change
    setFilters(null);
    setPage(1);
    setParsedFilters({});

    const newTopFilters = tabItems.find((item) => item.id === newActiveTab)?.filters || {};
    setFilters({ tab: newActiveTab });
    setActiveTab(newActiveTab);
    setTopFilters(newTopFilters);
  };

  const handleFilterChange = (field: JobsFilterField, values: string | string[]) => {
    setPage(1);
    setFilters({ ...filters, [field]: values });

    debounceFilterChange(() => {
      setParsedFilters(getJobFilters(parsedFilters, field, values));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handlePresentExportDrawer = () => {
    setShowExportDrawer(true);
  };

  const handleExportDatePickerChange = (start: Date, end?: Date) => {
    if (start && end) {
      setExportDatePeriod([start, end]);
    }
    setShowExportDatePicker(false);
  };

  const getDatePickerValue = () => {
    if (!exportDatePeriod[0]) {
      return '';
    }
    const startDate = format(new Date(exportDatePeriod[0]), 'dd.MM.yy');
    const endDate = format(new Date(exportDatePeriod[1]), 'dd.MM.yy');

    return `${startDate} - ${endDate}`;
  };

  const handleExport = async () => {
    const response = await api.getJobs({
      page,
      's[s]': 'id desc',
      ...stripObject(parsedFilters),
      export: 'xlsx',
      's[actual_created_at_gteq]': exportDatePeriod[0].toISOString(),
      's[actual_created_at_lteq]': exportDatePeriod[1].toISOString(),
    });

    if (response) {
      setShowExportDrawer(false);
      setExportDatePeriod([]);
    }
  };

  useEffect(() => {
    fetchJobStats();
  }, []);

  useEffect(() => {
    if (!jobsData) return;

    const appendData = jobsData.page > 1;
    setJobs(
      appendData && isLoadMoreTriggered ? (prev) => [...prev, ...jobsData.jobs] : jobsData.jobs,
    );
    setIsLoadMoreTriggered(false);
  }, [jobsData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="jobs-header">
            <FormattedMessage id={translationKeys.jobs_page_title} />
          </h1>
          <div>
            <Button
              data-testid="jobs-export-button"
              variant="gray"
              icon={<FontAwesomeIcon icon={faArrowDownToLine} />}
              onClick={handlePresentExportDrawer}
            >
              <FormattedMessage id={translationKeys.jobs_page_export} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <JobsTabs items={tabItems} activeTab={activeTab} onSelect={handleTabSelect} />
        </div>

        <div className={styles.tableContainer}>
          <JobsTable
            jobs={jobs}
            activeTab={activeTab}
            error={jobsError}
            isLoadMoreTriggered={isLoadMoreTriggered}
            isLoading={jobStatsLoading || isJobsLoading}
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </div>

        {showLoadMoreButton && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setIsLoadMoreTriggered(true);
                handleLoadMore();
              }}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
            </Button>
          </div>
        )}

        {!isJobsLoading && !!jobs.length && (
          <div>
            <Pagination
              currentPage={page}
              totalPages={jobsData?.pages || 1}
              setPage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>

      <Drawer
        title={intl.formatMessage({
          id: translationKeys.jobs_page_export_title,
        })}
        subtitle={intl.formatMessage({
          id: translationKeys.jobs_page_export_description,
        })}
        show={showExportDrawer}
        onClose={() => setShowExportDrawer(false)}
        data-testid="jobs-export-drawer"
      >
        <div className={styles.exportDrawerContent}>
          <div className={styles.datePicker}>
            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.jobs_page_export_date_picker_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.jobs_page_export_date_picker_label,
              })}
              icon={<FontAwesomeIcon icon={faCalendar} />}
              value={getDatePickerValue()}
              onFocus={() => setShowExportDatePicker(true)}
              onChange={() => setExportDatePeriod([])}
              size="large"
              type="search"
            />
            {showExportDatePicker && (
              <DatePicker
                monthCount={1}
                onClose={() => setShowExportDatePicker(false)}
                onChange={(start: Date, end?: Date) => handleExportDatePickerChange(start, end)}
              />
            )}
          </div>

          <Banner
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.jobs_page_export_info_text,
            })}
          />

          <div className={styles.exportButton}>
            <Button size="large" onClick={handleExport}>
              <FormattedMessage id={translationKeys.jobs_page_export_submit_button} />
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Jobs;
