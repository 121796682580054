import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faChevronDown,
  faEnvelope,
  faLocationDot,
  faPhone,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Tag, Tooltip } from '@skiwo/components';
import { format } from 'date-fns';
import { useGetJobInvitationLogs } from '../../../../Api/Endpoints/Jobs/Jobs.hooks';
import SystemLogLoader from '../../../../components/SystemLog/SystemLogLoader';
import { getGenderLabel } from '../../../../CreateInterpretationOrder/utils';
import usePagination from '../../../../hooks/usePagination';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useQualifications } from '../../../../providers/QualificationsProvider';
import translationKeys from '../../../../translations/translationKeys';
import { InterpretationSkill } from '../../../../types';
import JobInvitationLog from '../../../../types/JobInvitationLog';
import useGetJobIdFromParam from '../../../hooks/useGetJobIdFromParam';
import { DiscussionStatusTag } from '../helpers/DiscussionStatusTag';
import getTextForLog from '../helpers/getLogText';
import styles from './InvitationLogTabSection.module.scss';

const InvitationLogTabSection = () => {
  const intl = useIntl();
  const [toggledInterpreter, setToggledInterpreter] = useState<number>();
  const { getQualificationById } = useQualifications();
  const { getLanguageById } = useLanguages();

  const { pagination, setPagination } = usePagination();
  const [logs, setLogs] = useState<JobInvitationLog[]>([]);

  const jobId = useGetJobIdFromParam();

  const { data, isLoading } = useGetJobInvitationLogs({
    id: jobId,
    urlParams: { page: pagination.page },
  });

  const getInterpretationSkillTag = (skill: InterpretationSkill) => {
    const qualification = getQualificationById(skill.qualificationId);
    return (
      <span>
        <Tag
          variant="square"
          color={qualification?.isInTolkeregister ? 'success' : 'neutral'}
          size="small"
        >
          {qualification?.isInTolkeregister && qualification.tolkeregisterName
            ? qualification.tolkeregisterName
            : 'N/A'}
        </Tag>
        {getLanguageById(skill.langToId)}
      </span>
    );
  };

  useEffect(() => {
    if (!data) return;

    const appendData = data.pagination.page > 1;

    setLogs(appendData ? (prev) => [...prev, ...data.collection] : data.collection);
    setPagination({ page: data.pagination.page, totalPages: data.pagination.pages });
  }, [data]);

  return (
    <>
      <div className={styles.invitationLogTabSection}>
        {isLoading && !logs && <SystemLogLoader />}

        {logs.map((logItem, index) => {
          const createdAt = new Date(logItem.lastSystemLogCreatedAt);
          return (
            <div key={index}>
              <button className={styles.logItemButton}>
                <div className={styles.logItem}>
                  <div className={styles.avatar}>
                    <Avatar
                      altText={logItem.person.name}
                      url={logItem.person.avatar || undefined}
                      size="medium"
                    />
                  </div>

                  <div>
                    <div className={styles.logSubject}>
                      <Tooltip title={logItem.person.phone || undefined} copyable>
                        <FontAwesomeIcon icon={faPhone} size="sm" />
                      </Tooltip>
                      <Tooltip title={logItem.person.email} copyable>
                        <FontAwesomeIcon icon={faEnvelope} size="xs" />
                      </Tooltip>
                      <span data-testid="details-card-title">{logItem.person.name}</span>
                      <DiscussionStatusTag status={logItem.discussionStatus} />
                    </div>

                    <span className={styles.date}>{format(createdAt, 'dd.MM.yyyy HH:mm')}</span>
                  </div>
                </div>
                <Button
                  variant="transparent"
                  onClick={() =>
                    setToggledInterpreter(index !== toggledInterpreter ? index : undefined)
                  }
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              </button>
              {toggledInterpreter === index && (
                <div className={styles.logItemDetails}>
                  <div className={styles.headerValues}>
                    <span>
                      <FontAwesomeIcon icon={faLocationDot} />
                      {[logItem.city, logItem.country].join(', ')}
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faVenusMars} />
                      {getGenderLabel(logItem.person.gender, intl)}
                    </span>
                    {logItem.skills.map((skill) => getInterpretationSkillTag(skill))}
                  </div>
                  <div className={styles.logsWrapper}>
                    {logItem.systemLogs.length ? (
                      logItem.systemLogs.map((log, index) => (
                        <div key={index} className={styles.logItem}>
                          <div>
                            <div className={styles.logSubject}>
                              <span>{getTextForLog(log, intl)}</span>
                            </div>

                            <span className={styles.date}>
                              {format(new Date(log.createdAt), 'dd.MM.yyyy HH:mm')}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.logSubject}>
                        <FormattedMessage id={translationKeys.job_system_log_no_logs} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {pagination.page < pagination.totalPages && (
        <div className={styles.loadMoreButton}>
          <Button
            size="large"
            variant="secondary"
            isLoading={isLoading}
            onClick={() => {
              setPagination({ ...pagination, page: pagination.page + 1 });
            }}
          >
            <FormattedMessage id={translationKeys.translation_order_system_logs_load_more_button} />
          </Button>
        </div>
      )}
    </>
  );
};

export default InvitationLogTabSection;
