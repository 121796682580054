import { useQuery } from '@tanstack/react-query';
import { UrlParams } from '../../fetchApi';
import Translations from './Translations';

export const translationsQueryKeys = {
  all: ['translations'],
  filters: (filters: UrlParams) => [...translationsQueryKeys.all, { filters }],
};

export const useGetTranslationSubtasksQuery = (urlParams: UrlParams, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: translationsQueryKeys.filters(urlParams),
    queryFn: () => Translations.getTranslationSubtasks(urlParams).then((res) => res.data),
    ...options,
  });
