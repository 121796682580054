import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faPage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePicker,
  Dropdown,
  EmptyState,
  SearchDropdown,
  Table,
  TextField,
} from '@skiwo/components';
import classnames from 'classnames';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import emptyStateIllustration from '../../assets/empty-table-none-available.svg';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { TranslationSubtask, TranslationSubtaskStatus } from '../../types';
import { TranslationSubtaskCustomerInput } from '../../types/TranslationSubtask';
import TranslationSubtaskItem from '../TranslationSubtaskItem/TranslationSubtaskItem';
import TranslationSubtaskItemSkeleton from '../TranslationSubtaskItemSkeleton/TranslationSubtaskItemSkeleton';
import { TranslationSubtasksFilters } from '../TranslationSubtasks';
import TranslationSubtasksFilterField from '../TranslationSubtasksFilterField';
import getCustomerInputStatusName from '../utils/getCustomerInputStatusName';
import getStatusName from '../utils/getStatusName';
import { customerInputIds, statusCodes } from '../utils/getTranslationSubtasksFilters';
import styles from './TranslationSubtasksTable.module.scss';

interface Props {
  subtasks: TranslationSubtask[];
  isLoading: boolean;
  error: ApiError | null;
  filters: TranslationSubtasksFilters;
  onFilterChange: (field: TranslationSubtasksFilterField, value: string | string[]) => void;
  onCompleteAction: (hardReload: boolean) => void;
  isLoadMoreTriggered?: boolean;
}

const TranslationSubtasksTable = ({
  subtasks,
  isLoading,
  isLoadMoreTriggered,
  error,
  filters,
  onFilterChange,
  onCompleteAction,
}: Props) => {
  const { languages } = useLanguages();
  const [activeDatePicker, setActiveDatePicker] = useState<TranslationSubtasksFilterField | null>();
  const intl = useIntl();

  const handleFilterChange = (field: TranslationSubtasksFilterField, value: string | string[]) => {
    onFilterChange(field, value);
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  const handleDatePickerClose = () => {
    setActiveDatePicker(null);
  };

  const handleDateChange = (field: TranslationSubtasksFilterField, e: React.ChangeEvent<any>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      onFilterChange(field, ['', '']);
      setActiveDatePicker(null);
    }
  };

  const handleDatePickerChange = (
    field: TranslationSubtasksFilterField,
    start: Date,
    end?: Date,
  ) => {
    if (field === TranslationSubtasksFilterField.ExternalDeadline && end) {
      onFilterChange(TranslationSubtasksFilterField.ExternalDeadline, [
        start.toISOString(),
        end.toISOString(),
      ]);
    }

    if (field === TranslationSubtasksFilterField.InternalDeadline && end) {
      onFilterChange(TranslationSubtasksFilterField.InternalDeadline, [
        start.toISOString(),
        end.toISOString(),
      ]);
    }

    if (field === TranslationSubtasksFilterField.CreatedAt && end) {
      onFilterChange(TranslationSubtasksFilterField.CreatedAt, [
        start.toISOString(),
        end.toISOString(),
      ]);
    }

    handleDatePickerClose();
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  const statusOptions = [
    ...(Object.values(TranslationSubtaskCustomerInput).map((customerInput) => {
      return {
        id: customerInputIds[customerInput],
        label: getCustomerInputStatusName(customerInput, intl),
        key: customerInput.toString(),
      };
    }) || []),
    ...(Object.values(TranslationSubtaskStatus).map((status) => {
      return {
        id: statusCodes[status],
        label: getStatusName(status, intl),
        key: status.toString(),
      };
    }) || []),
  ];

  const getDateRangeValue = (dateRange: string[] | null) => {
    return (
      dateRange
        ?.filter((item) => item !== '')
        .map((item) => getFormattedDate(item.toString()))
        .join(' - ') || ''
    );
  };

  if (error) {
    return (
      <div className={styles.emptyStateContainer} data-testid="error-state">
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({ id: translationKeys.translation_subtasks_error_state_title })}
          description={intl.formatMessage({
            id: translationKeys.translation_subtasks_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="translation-subtasks-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_id_filter_placeholder,
                })}
                value={filters[TranslationSubtasksFilterField.Id] || ''}
                onChange={(e) => {
                  handleFilterChange(TranslationSubtasksFilterField.Id, e.currentTarget.value);
                }}
                data-testid="id-filter"
              />
            </th>
            <th className={styles.hintsFilter}>
              <div className={styles.hints}>
                <Dropdown
                  toggle={
                    <FontAwesomeIcon
                      icon={faPage}
                      className={classnames(styles.icon, {
                        [styles.active]: !!filters[TranslationSubtasksFilterField.Document],
                      })}
                      data-testid="document-icon"
                    />
                  }
                  onSelect={(selection) => {
                    handleFilterChange(TranslationSubtasksFilterField.Document, selection || '');
                  }}
                  selectedItemId={filters[TranslationSubtasksFilterField.Document] || ''}
                  items={[
                    {
                      id: 'true',
                      text: intl.formatMessage({
                        id: translationKeys.translation_subtasks_document_attached,
                      }),
                      icon: faPage,
                    },
                    {
                      id: 'false',
                      text: intl.formatMessage({
                        id: translationKeys.translation_subtasks_no_document_attached,
                      }),
                      icon: faPage,
                      variant: 'inactive',
                    },
                  ]}
                />
              </div>
            </th>
            <th className={styles.statusFilter} data-testid="status-filter">
              <SearchDropdown
                options={statusOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_status_filter_placeholder,
                })}
                selectedKeys={filters[TranslationSubtasksFilterField.Status] || []}
                multiple
                onChange={(statuses) => {
                  if (statuses && statuses.length > 0) {
                    handleFilterChange(
                      TranslationSubtasksFilterField.Status,
                      statuses.map((status) => status.key),
                    );
                  } else {
                    handleFilterChange(TranslationSubtasksFilterField.Status, []);
                  }
                }}
              />
            </th>
            <th className={styles.ownerFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_owner_filter_placeholder,
                })}
                onChange={(e) =>
                  handleFilterChange(TranslationSubtasksFilterField.Owner, e.currentTarget.value)
                }
                data-testid="owner-filter"
                value={filters[TranslationSubtasksFilterField.Owner] || ''}
              />
            </th>
            <th className={styles.languageFilter} data-testid="language-filter">
              <SearchDropdown
                maxResults={languageOptions.length}
                options={languageOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_language_filter_placeholder,
                })}
                multiple
                onChange={(languages) => {
                  if (languages && languages.length > 0) {
                    const languageIds = languages.map((language) => language.id);
                    handleFilterChange(
                      TranslationSubtasksFilterField.Languages,
                      languageIds.map((id) => id.toString()),
                    );
                  } else {
                    handleFilterChange(TranslationSubtasksFilterField.Languages, []);
                  }
                }}
                selectedKeys={filters[TranslationSubtasksFilterField.Languages] || []}
              />
            </th>
            <th className={styles.dateFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_external_deadline_filter_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setActiveDatePicker(TranslationSubtasksFilterField.ExternalDeadline)}
                value={getDateRangeValue(filters.externalDeadline)}
                onChange={(e) =>
                  handleDateChange(TranslationSubtasksFilterField.ExternalDeadline, e)
                }
                type="search"
                data-testid="external-date-filter"
              />

              {activeDatePicker === TranslationSubtasksFilterField.ExternalDeadline && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={handleDatePickerClose}
                  monthCount={1}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(
                      TranslationSubtasksFilterField.ExternalDeadline,
                      start,
                      end,
                    )
                  }
                />
              )}
            </th>
            <th className={styles.dateFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_internal_deadline_filter_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setActiveDatePicker(TranslationSubtasksFilterField.InternalDeadline)}
                value={getDateRangeValue(filters.internalDeadline)}
                onChange={(e) =>
                  handleDateChange(TranslationSubtasksFilterField.InternalDeadline, e)
                }
                type="search"
                data-testid="internal-date-filter"
              />

              {activeDatePicker === TranslationSubtasksFilterField.InternalDeadline && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={handleDatePickerClose}
                  monthCount={1}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(
                      TranslationSubtasksFilterField.InternalDeadline,
                      start,
                      end,
                    )
                  }
                />
              )}
            </th>
            <th className={styles.dateFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_created_at_filter_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setActiveDatePicker(TranslationSubtasksFilterField.CreatedAt)}
                value={getDateRangeValue(filters.createdAt)}
                onChange={(e) => handleDateChange(TranslationSubtasksFilterField.CreatedAt, e)}
                type="search"
                data-testid="created-at-filter"
              />

              {activeDatePicker === TranslationSubtasksFilterField.CreatedAt && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={handleDatePickerClose}
                  monthCount={1}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(TranslationSubtasksFilterField.CreatedAt, start, end)
                  }
                />
              )}
            </th>
            <th className={styles.translatorFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_translator_filter_placeholder,
                })}
                onChange={(e) =>
                  handleFilterChange(
                    TranslationSubtasksFilterField.Translator,
                    e.currentTarget.value,
                  )
                }
                data-testid="translator-filter"
                value={filters[TranslationSubtasksFilterField.Translator] || ''}
              />
            </th>
            <th className={styles.wordCountFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_subtasks_word_count_filter_placeholder,
                })}
                onChange={(e) =>
                  handleFilterChange(
                    TranslationSubtasksFilterField.WordCount,
                    e.currentTarget.value,
                  )
                }
                data-testid="word-count-filter"
                value={filters[TranslationSubtasksFilterField.WordCount] || ''}
              />
            </th>
            <th></th>
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_documents_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_owner_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_language_header} />
            </th>
            <th>
              <FormattedMessage
                id={translationKeys.translation_subtasks_external_deadline_header}
              />
            </th>
            <th>
              <FormattedMessage
                id={translationKeys.translation_subtasks_internal_deadline_header}
              />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_created_at_header} />
            </th>
            <th>
              {' '}
              <FormattedMessage id={translationKeys.translation_subtasks_translator_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_word_count_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_subtasks_notes_header} />
            </th>
          </tr>
        </thead>

        <tbody>
          {!(isLoading && !isLoadMoreTriggered) &&
            subtasks.map((subtask) => (
              <TranslationSubtaskItem
                subtask={subtask}
                key={subtask.id}
                onCompleteAction={handleCompleteAction}
                onIdFilter={(value) => {
                  handleFilterChange(TranslationSubtasksFilterField.OrderId, value);
                }}
                data-testid="subtask-item"
              />
            ))}

          {isLoading &&
            [...Array(3)].map((_, index) => <TranslationSubtaskItemSkeleton key={index} />)}
        </tbody>
      </Table>

      {!isLoading && subtasks.length === 0 && (
        <div className={styles.emptyStateContainer}>
          <EmptyState
            image={emptyStateIllustration}
            text={intl.formatMessage({
              id: translationKeys.translation_subtasks_empty_state_title,
            })}
            description={intl.formatMessage({
              id: translationKeys.translation_subtasks_empty_state_subtitle,
            })}
            data-testid="empty-state"
          />
        </div>
      )}
    </>
  );
};

export default TranslationSubtasksTable;
