import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Pagination, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  ParserBuilder,
  useQueryState,
  useQueryStates,
  Values,
} from 'nuqs';
import { useGetInterpretationSkillsQuery } from '../Api/Endpoints/Skills/Skills.hooks';
import useDebounce from '../hooks/useDebounce';
import translationKeys from '../translations/translationKeys';
import { InterpretationSkill, InterpretationSkillsStatus } from '../types';
import {
  InterpretationSkillSex,
  InterpretationSkillsStatistics,
} from '../types/InterpretationSkill';
import NewSkillDrawer from './Drawers/NewSkillDrawer/NewSkillDrawer';
import InterpretationSkillsTable from './InterpretationSkillsTable/InterpretationSkillsTable';
import getInterpretationSkillsFilters from './utils/getInterpretationSkillsFilters';
import getStatusName from './utils/getStatusName';
import InterpretationSkillsFilterField from './InterpretationSkillsFilterField';
import InterpretationSkillsTab from './InterpretationSkillsTab';
import styles from './InterpretationSkills.module.scss';

export type InterpretationSkillsFilters = Values<{
  tab: ParserBuilder<InterpretationSkillsTab>;
  id: ParserBuilder<number>;
  name: ParserBuilder<string>;
  languages: ParserBuilder<string[]>;
  qualifications: ParserBuilder<string[]>;
  createdAt: ParserBuilder<string[]>;
  gender: ParserBuilder<InterpretationSkillSex>;
  location: ParserBuilder<string>;
  accountStatuses: ParserBuilder<string[]>;
  standby: ParserBuilder<string>;
}>;

const InterpretationSkills = () => {
  const [skills, setSkills] = useState<InterpretationSkill[]>([]);
  const [statistics, setStatistics] = useState<InterpretationSkillsStatistics>();
  const [newSkillDrawerOpen, setNewSkillDrawerOpen] = useState(false);
  const intl = useIntl();
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [filters, setFilters] = useQueryStates({
    tab: parseAsStringEnum<InterpretationSkillsTab>(Object.values(InterpretationSkillsTab)),
    id: parseAsInteger,
    name: parseAsString,
    languages: parseAsArrayOf(parseAsString),
    qualifications: parseAsArrayOf(parseAsString),
    createdAt: parseAsArrayOf(parseAsString),
    gender: parseAsStringEnum<InterpretationSkillSex>(Object.values(InterpretationSkillSex)),
    location: parseAsString,
    accountStatuses: parseAsArrayOf(parseAsString),
    standby: parseAsString,
  });
  const [activeTab, setActiveTab] = useState(filters.tab || InterpretationSkillsTab.Approved);
  const [isLoadMoreTriggered, setIsLoadMoreTriggered] = useState(false);
  const debounceFilterChange = useDebounce(300);

  const tabItems = [
    {
      active: true,
      count: statistics?.approved ? statistics.approved : undefined,
      id: InterpretationSkillsTab.Approved,
      title: getStatusName(InterpretationSkillsStatus.Approved, intl),
      filters: {
        's[status_eq]': '30',
      },
    },
    {
      count: statistics?.learning ? statistics.learning : undefined,
      id: InterpretationSkillsTab.Learning,
      title: getStatusName(InterpretationSkillsStatus.Learning, intl),
      filters: {
        's[status_eq]': '20',
      },
    },
    {
      count: statistics?.pending ? statistics.pending : undefined,
      id: InterpretationSkillsTab.Pending,
      title: getStatusName(InterpretationSkillsStatus.Pending, intl),
      filters: {
        's[status_eq]': '10',
      },
    },
    {
      count: statistics?.declined ? statistics.declined : undefined,
      id: InterpretationSkillsTab.Declined,
      title: getStatusName(InterpretationSkillsStatus.Declined, intl),
      filters: {
        's[status_eq]': '40',
      },
    },
    {
      count: statistics?.all ? statistics.all : undefined,
      id: InterpretationSkillsTab.All,
      title: intl.formatMessage({
        id: translationKeys.interpretation_skills_page_all_tab,
      }),
      filters: { 's[status_eq]': '' },
    },
  ];

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);

    if (activeTabItem) {
      return activeTabItem.filters;
    } else return {};
  };

  const [parsedFilters, setParsedFilters] = useState<Record<string, string | string[]>>({
    ...(tabItems.find((item) => item.id === activeTab)?.filters || {}),
    ...Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc = getInterpretationSkillsFilters(
          acc,
          key as InterpretationSkillsFilterField,
          value as string,
        );
      }
      return acc;
    }, {}),
  });

  const [topFilters, setTopFilters] = useState(getTabFilters());

  const {
    data: skillsData,
    isLoading: isSkillsLoading,
    error: skillsError,
    refetch,
  } = useGetInterpretationSkillsQuery({
    page,
    's[s]': 'id desc',
    ...stripObject(parsedFilters),
    ...stripObject(topFilters),
  });
  const showLoadMoreButton = !isSkillsLoading && skills && skillsData && page < skillsData.pages;

  const handleFilterChange = (field: InterpretationSkillsFilterField, value: string | string[]) => {
    setPage(1);
    setFilters({ ...filters, [field]: value });

    debounceFilterChange(() => {
      setParsedFilters(getInterpretationSkillsFilters(parsedFilters, field, value));
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleReloadData = () => {
    refetch();
  };

  const handleTabSelect = (newActiveTab: string) => {
    setPage(1);
    const newTab = newActiveTab as InterpretationSkillsTab;
    const newTopFilters = tabItems.find((item) => item.id === newActiveTab)?.filters || {};
    setFilters({ ...filters, tab: newTab });
    setActiveTab(newTab);
    setTopFilters(newTopFilters);
  };

  useEffect(() => {
    if (!skillsData) return;

    const appendData = skillsData.page > 1;

    setSkills(
      appendData && isLoadMoreTriggered
        ? (prev) => [...prev, ...skillsData.skills]
        : skillsData.skills,
    );
    setStatistics(skillsData.statistics);
    setIsLoadMoreTriggered(false);
  }, [skillsData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="interpretation-skills-header">
            <FormattedMessage id={translationKeys.interpretation_skills_page_title} />
          </h1>

          <div>
            <Button
              icon={<FontAwesomeIcon icon={faPlus} />}
              data-testid="new-skill-button"
              onClick={() => setNewSkillDrawerOpen(true)}
            >
              <FormattedMessage id={translationKeys.interpretation_skills_page_new_skill_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} activeTab={activeTab} />
        </div>

        <div className={styles.tableContainer}>
          <InterpretationSkillsTable
            skills={skills}
            error={skillsError}
            isLoadMoreTriggered={isLoadMoreTriggered}
            isLoading={isSkillsLoading}
            filters={filters}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
          />
        </div>

        {showLoadMoreButton && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                handleLoadMore();
                setIsLoadMoreTriggered(true);
              }}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.interpretation_skills_page_load_more_button} />
            </Button>
          </div>
        )}

        {!isSkillsLoading && !!skills.length && (
          <div>
            <Pagination
              currentPage={page}
              totalPages={skillsData?.pages || 1}
              setPage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>

      <NewSkillDrawer
        show={newSkillDrawerOpen}
        onClose={() => setNewSkillDrawerOpen(false)}
        onComplete={() => handleReloadData()}
      />
    </>
  );
};

export default InterpretationSkills;
